import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Menu, Affix, Row, Col, Layout } from "antd";
import "./header.css";
import Banner from "../assets/images/item_613.png";
import HeartIcon from "../assets/images/item_1210.png";
import PlaylistModal from "../components/modal/playlistModal";

const { Header } = Layout;
const Headermenu = () => {
  const [isCreateModalVisible, setisCreateModalVisible] = useState(false);
  const togglePlaylistModal = () => {
    setisCreateModalVisible(!isCreateModalVisible);
  };
  const playList = useSelector(
    (state) => state.DisciplineAndInterventionReducer.playList
  );
  const openSubmission = () => {
    window.open(
      "https://erbeelektromedizin.eu.qualtrics.com/jfe/form/SV_2fWg705ugocblhr",
      "_blank"
    );
  };
  return (
    <>
      <div className="headerPlayList">
        <PlaylistModal
          isVisible={isCreateModalVisible}
          handleOk={togglePlaylistModal}
          handleCancel={togglePlaylistModal}
        />
      </div>
      <Affix>
        <Menu mode="horizontal">
          <Header style={{ width: "100%", height: "100%" }}>
            <Row gutter={20}>
              <Col
                xs={22}
                sm={22}
                md={7}
                lg={7}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "3%" }}>
                  <img src={Banner} width={200} onClick={()=>window.open("https://in.erbe-med.com/in-en/")} />
                </div>
              </Col>
              <Col
                xs={2}
                sm={2}
                md={17}
                lg={17}
                style={{
                  padding: "2%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Menu
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  theme="dark"
                  mode="horizontal"
                >
                  <Menu.Item
                    onClick={togglePlaylistModal}
                    key="1"
                    style={{
                      fontWeight: "bold",
                      color: "rgb(40 171 227) ",
                      fontSize: "1.3vw",
                    }}
                  >
                    <span>
                      {" "}
                      <img src={HeartIcon} width={18} />
                    </span>{" "}
                    PLAYLIST{" "}
                    {playList &&
                    playList !== null &&
                    playList !== "" &&
                    playList !== undefined &&
                    playList.length > 0 ? (
                      <span>[{playList.length}]</span>
                    ) : (
                      ""
                    )}
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    style={{
                      fontWeight: "bold",
                      color: "rgb(40 171 227) ",

                      fontSize: "1.3vw",
                    }}
                  >
                    WHAT IS VIO ON VIDEO{" "}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => openSubmission()}
                    key="3"
                    style={{
                      fontWeight: "bold",
                      color: "rgb(40 171 227) ",

                      fontSize: "1.3vw",
                    }}
                  >
                    SUBMIT A VIDEO
                  </Menu.Item>
                  <Menu.Item
                    key="4"
                    style={{
                      fontWeight: "bold",
                      color: "rgb(40 171 227) ",
                      fontSize: "1.3vw",
                    }}
                  >
                    FAQ
                  </Menu.Item>
                </Menu>
              </Col>
            </Row>
          </Header>
        </Menu>
      </Affix>
    </>
  );
};
export default Headermenu;
