import axios from "axios";
import { BASEURL } from "../config";

export const getModes = (intro, discipline) => {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  let formData = {
    Intervention: intro,
    Discipline: discipline,
  };
  return (dispatch) => {
    dispatch({
      type: "GET_MODES",
      payload: {
        loading: true,
      },
    });
    axios
      .post(`${BASEURL}/modes`, formData, headers)
      .then((res) => {
        dispatch({
          type: "GET_MODES_SUCCESS",
          payload: {
            data: res.data,
            loading: false,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "GET_MODES_ERROR",
          payload: {
            loading: false,
          },
        });
      });
  };
};
export const clearModes = () => {
  return (dispatch) => {
    dispatch({
      type: "GET_MODES_SUCCESS",
      payload: {
        data: [],
        loading: false,
      },
    });
  };
};
