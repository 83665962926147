import axios from "axios";
import { BASEURL } from "../config";

export const getMatrix_data = (discipline, intro, mode) => {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  let formData = {
    Intervention: intro,
    Discipline: discipline,
    Mode: mode,
  };
  return (dispatch) => {
    dispatch({
      type: "GET_MATRIX",
      payload: {
        loading: true,
      },
    });
    axios
      .post(`${BASEURL}/matrix`, formData, headers)
      .then((res) => {
        dispatch({
          type: "GET_MATRIX_SUCCESS",
          payload: {
            data: res.data,
            loading: false,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "GET_MATRIX_ERROR",
          payload: {
            loading: false,
          },
        });
      });
  };
};
export const getMatrixvideos_data = (
  inter,
  discipline,
  mode,
  submode,
  effect
) => {
  let formData = {
    Intervention: inter,
    Discipline: discipline,
    Mode: mode,
    SubMode: submode,
    Effect: effect,
  };
  return (dispatch) => {
    dispatch({
      type: "GET_MATRIXVIDEOS",
      payload: {
        loading: true,
      },
    });
    axios
      .post(`${BASEURL}/matrix/videos`, formData)
      .then((res) => {
        dispatch({
          type: "GET_MATRIXVIDEOS_SUCCESS",
          payload: {
            data: res?.data,
            loading: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MATRIXVIDEOS_ERROR",
          payload: {
            loading: false,
          },
        });
        console.log(error);
      });
  };
};
export const getendoCUT_data = (
  discipline,
  intro,
  mode,
  effect,
  duration,
  interval
) => {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  let formData = {
    Intervention: intro,
    Discipline: discipline,
    Mode: mode,
    Effect: effect,
    Duration: duration,
    Interval: interval,
  };
  return (dispatch) => {
    dispatch({
      type: "GET_ENDOCUT_DATA",
      payload: {
        loading: true,
      },
    });
    axios
      .post(`${BASEURL}/endocut/videos`, formData, headers)
      .then((res) => {
        dispatch({
          type: "GET_ENDOCUT_DATA_SUCCESS",
          payload: {
            data: res.data,
            loading: false,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "GET_ENDOCUT_DATA_ERROR",
          payload: {
            loading: false,
          },
        });
      });
  };
};
export const getendoCUTTopConfigs = (discipline, intro, mode) => {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  let formData = {
    Intervention: intro,
    Discipline: discipline,
    Mode: mode,
  };
  return (dispatch) => {
    dispatch({
      type: "GET_ENDOCUT_TOP_CONFIG_DATA",
      payload: {
        loading: true,
      },
    });
    axios
      .post(`${BASEURL}/endocut/top`, formData, headers)
      .then((res) => {
        dispatch({
          type: "GET_ENDOCUT_TOP_CONFIG_DATA_SUCCESS",
          payload: {
            data: res.data,
            loading: false,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "GET_ENDOCUT_TOP_CONFIG_DATA_ERROR",
          payload: {
            loading: false,
          },
        });
      });
  };
};
export const clearMatrix_data = () => {
  return (dispatch) => {
    dispatch({
      type: "GET_MATRIX_SUCCESS",
      payload: {
        data: [],
        loading: false,
      },
    });
  };
};
export const clearMatrixvideos_data = () => {
  return (dispatch) => {
    dispatch({
      type: "GET_MATRIXVIDEOS_SUCCESS",
      payload: {
        data: [],
        loading: false,
      },
    });
  };
};
export const cleargetendoCUT_data = () => {
  return (dispatch) => {
    dispatch({
      type: "GET_ENDOCUT_DATA_SUCCESS",
      payload: {
        data: [],
        loading: false,
      },
    });
  };
};
