import React from "react";
import { Modal, Row, Col, Card, Popover } from "antd";
import { Spin } from "antd";

const { Meta } = Card;

function CoagModal({
  isVisible,
  handleOk,
  handleCancel,
  matrixData,
  addToPlaylist,
  removeFromPlaylist,
  checkIfVideoInPlaylist,
  selectedEffect,
  selectedSubMode,
}) {
  return (
    <div className="coagModal">
      <Modal
        wrapClassName="videoPlayer"
        visible={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        bodyStyle={{ backgroundColor: "black" }}
        centered
        width={"80%"}
        footer={null}
      >
        <div className="world_videos">
          <Row
            style={{
              color: "white",
              fontSize: "25px",
              fontFamily: "Netto Regular",
              display:"flex",
              justifyContent:'space-between'
            }}
          >
            {console.log('the title is', matrixData)}
            <Col>
              <p>
                {matrixData.videosList && matrixData.videosList.data.length && matrixData.videosList.data[0].Title  }
              </p>
            </Col>
            <Col>
            <span style={{color:'white'}}>
            <i class="fas fa-times"></i>
            </span>
            </Col>
          </Row>
          {matrixData.videosList.loading === true ? (
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10%",
              }}
            >
              <Col>
                <Spin tip="Loading.." size="large"></Spin>
              </Col>
            </Row>
          ) : (
            <Row gutter={24}>
              {matrixData && matrixData.videosList.data.length > 0 ? (
                matrixData?.videosList.data.map((video, idx) => (
                  <Col key={idx}>
                    <Popover
                    content={<div>
                      <p style={{fontWeight:'bold'}}>Procedure </p>
                      {video?.Procedure}</div>}
                    title={<div>{video?.Mode} - {video?.Effect}</div>}
                      trigger="hover"
                    >
                      <Card
                        hoverable
                        bordered={true}
                        style={{ borderRadius: "10px",marginBottom:'10px' }}
                        cover={
                          <iframe
                            id={video.VideoId}
                            src={`https://player.vimeo.com/video/${video.VideoId}`}
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                            style={{
                              position: "inherit",
                              top: "0px",
                              left: "0px",
                              width: "329px",
                              height: "180px",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                            }}
                            title=""
                          ></iframe>
                        }
                      >
                        <Meta
                          style={{ color: "white" }}
                          title={
                            <Row>
                              <Col
                                style={{
                                  overflow: "hidden",
                                  fontFamily: "Netto Regular",
                                  marginTop: "8px",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                                span={22}
                              >
                                {video?.Procedure}
                              </Col>
                              <Col span={2}>
                                {" "}
                                <span
                                  style={{ fontSize: "25px" }}
                                  onClick={() =>
                                    checkIfVideoInPlaylist(video) == true
                                      ? removeFromPlaylist(
                                          video,
                                          `video_playlistBtn_${idx}_mode`
                                        )
                                      : addToPlaylist(
                                          video,
                                          `video_playlistBtn_${idx}_mode`
                                        )
                                  }
                                >
                                  <i
                                    class="fas fa-heart"
                                    id={`video_playlistBtn_${idx}_mode`}
                                    style={{
                                      color:
                                        checkIfVideoInPlaylist(video) == true
                                          ? "red"
                                          : "white",
                                    }}
                                  ></i>
                                </span>
                                {/* <img
                                src={Icon}
                                alt={"Add to playlist"}
                                className="img-responsive"
                              /> */}
                              </Col>
                            </Row>
                          }
                        />
                      </Card>
                    </Popover>
                  </Col>
                ))
              ) : (
                <Col
                  style={{
                    height: "100px",
                    background: "transparent",
                    color: "white",
                    fontSize: "30px",
                    display: "flex",
                    justifyContent: "center",
                    padding: "150px",
                    fontFamily: "Netto Light",
                  }}
                  className="chartTitle"
                >
                  <center>
                    <p>
                      {" "}
                      No Videos Found for the selected values. Please try other
                      combinations.
                    </p>
                  </center>
                </Col>
              )}
            </Row>
          )}
        </div>
      </Modal>
    </div>
  );
}
export default CoagModal;
