import React  from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { Map, GoogleApiWrapper,Marker } from 'google-maps-react';
import "../dashboard/index.css";
import {G_MAPS_API_KEY} from '../../config'
export class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    console.log('the props data-> ',this.props.mapData)
    this.state = {
      stores:
       []
    }
  }
  componentDidMount(){
    let map_data = this.props.mapData?.mapData?.data
    let locationsMap = []
    map_data.map(item => {
      
      item.Cities.map(
        city => {
          if(city.Lat && city.Long){
            let obs = {
              latitude:city.Lat || '',
              longitude:city.Long || '',
              city:city.City||"",
              country:city.Country||""
            }
            locationsMap.push(obs)
          }
        }
      )
     
    })
    this.setState({stores:locationsMap})
  }
  displayMarkers = () => {
    return this.state.stores.map((store, index) => {
      return <Marker key={index} name={`${store.city}_${store.country}`}  id={index} position={{
       lat: store.latitude,
       lng: store.longitude
     }}
     
     onClick={(val) => 
      {console.log('the map click is', val)
      let city=val.name.split('_')[0] || ""
      let country=val.name.split('_')[1] || ""

        this.props.handleMapMarkerClick(city,country)}} />
    })
  }

  render() {
    return (
        <Map
          google={this.props.google}
           initialCenter={{
            lat: 40.854885,
            lng: -88.081807
          }}
          zoom={2}
          style={{
            height:"62vh",
            width:this.props.width,
            position:'fixed',
          }}
        >
          {this.displayMarkers()}
        </Map>
    );
  }
}
export default GoogleApiWrapper({
  apiKey: G_MAPS_API_KEY
})(MapContainer);
