import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Card, Checkbox, Popover, Button,Popconfirm, message } from "antd";
import { useDispatch } from "react-redux";
import {updatePlayList} from '../../Actions/DisciplineAndInterventions'
import Item_1 from "../../assets/images/item_16095.png";
import Item_2 from "../../assets/images/item_16033.png";
import Item_3 from "../../assets/images/item_15832.png";
import Item_4 from "../../assets/images/item_16020.png";
import Item_5 from "../../assets/images/item_16105.png";
import Item_6 from "../../assets/images/item_16078.png";
import Item_7 from "../../assets/images/item_16059.png";
import Item_8 from "../../assets/images/item_16070.png";
import Item_9 from "../../assets/images/item_16045.png";
import { useSelector } from "react-redux";
import "./style.css";

const { Meta } = Card;
function PlaylistModal({ isVisible, handleOk, handleCancel }) {
  const dispatch = useDispatch()
  const [playList, setPlayList] = useState([]);
  const playLists = useSelector(
    (state) => state.DisciplineAndInterventionReducer.playList
  );
  const [selectedVideos, setSelectedVideos] = useState([]);
  useEffect(() => {
    let existingData = playLists;
    if (
      existingData !== "" &&
      existingData !== undefined &&
      existingData !== null &&
      existingData.length > 0
    ) {
      setPlayList(existingData);
    }
  }, [playLists]);

  const handleSelect = (e, video) => {
    let isSelected = e.target.checked;
    let selectedVid = [...selectedVideos];
    if (isSelected === true) {
      let index = selectedVideos.map((o) => o.VideoId).indexOf(video.VideoId);
      if (index <= -1) {
        selectedVid.push(video);
        setSelectedVideos(selectedVid);
      }
    } else {
      let index = selectedVideos.map((o) => o.VideoId).indexOf(video.VideoId);
      selectedVid.splice(index, 1);
      setSelectedVideos(selectedVid);
    }
  };
  const clearPlaylist = () => {
    localStorage.removeItem("PlayList")
    setPlayList([])
    dispatch(updatePlayList([]))
    let dom_nodes = [...document.getElementsByClassName("fas fa-heart")] || []
    dom_nodes.map(node => node.style.color = "white")
  }
  return (
    <Modal
      centered
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      bodyStyle={{ fontFamily: "Netto Regular" }}
      width={"80%"}
      height={"70%"}
      footer={playList &&
        playList !== null &&
        playList.length > 0 &&
        // playList !== "" &&
        playList !== undefined ? [
          <Popconfirm placement="top" title="Are you sure to clear playlist?" onConfirm={() => clearPlaylist()} okText="Yes" cancelText="No">
          <Button style={{background:"#00aeef !important"}} key="clear_playlist" type="primary">Clear Playlist</Button>
        </Popconfirm>
      ]:null}
    >
      <Row>
        <Col>
          <p style={{ fontSize: "2vw", fontWeight: 600 }}>Your Playlist</p>
        </Col>
      </Row>

      {playList &&
          playList !== null &&
          playList.length > 0 &&
          // playList !== "" &&
          playList !== undefined ? (
            <>
      {/* <Row>
        <Col xs={18} sm={18} md={18} lg={18}>
          <span
            style={{
              // padding: "1%",
              fontSize: "1.3vw",
            }}
          >
            Ecabori sitem explam vendips anihic testemp erciunt apid quiatur sim
            quae pore optatiumernam cor sequiscium quo. Ficiataque ex eatis
            consed molo conseri busdae cusdam, consenis mi, cores porenditia
            doloresequi qui beritius, tem quis quas quias di dis etur, vel
            estiis imporem poruptas modignis ut
          </span>
        </Col>
      </Row> */}
      {/* <div style={{ marginTop: "10px" }}>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Row>
              <Col md={2} lg={2} sm={2} xs={2}>
                <img src={Item_1} width={40} />
              </Col>
              <Col md={2} lg={2} sm={2} xs={2}>
                <img src={Item_2} width={40} />
              </Col>
              <Col md={2} lg={2} sm={2} xs={2}>
                <img src={Item_3} width={40} />
              </Col>
              <Col md={2} lg={2} sm={2} xs={2}>
                <img src={Item_4} width={40} />
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            // style={{ padding: "1%" }}
          >
            <Row justify="end">
              <Col md={2} lg={2} sm={2} xs={2}>
                <img src={Item_5} width={45} />
              </Col>
              <Col md={1} lg={1} sm={1} xs={1} style={{ margin: "3px" }}>
                <img src={Item_6} width={25} />
              </Col>
              <Col md={1} lg={1} sm={1} xs={1} style={{ margin: "3px" }}>
                <img src={Item_7} width={25} />
              </Col>
              <Col md={1} lg={1} sm={1} xs={1} style={{ margin: "3px" }}>
                <img src={Item_8} width={25} />
              </Col>
              <Col md={1} lg={1} sm={1} xs={1} style={{ margin: "3px" }}>
                <img src={Item_9} width={25} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div> */}
      <div className="world_videos" style={{ marginTop: "3%" }}>
        <Row gutter={24}>
       
           { playList.map((video) => (
              <Popover
              content={<div>
                <p style={{fontWeight:'bold'}}>Procedure </p>
                {video?.Procedure}</div>}
              title={
                video?.Mode.indexOf("endoCUT") <= -1 ?
              <div>{video?.Mode} - {video?.Effect}</div>
            :
            <div>{video?.Mode} - E{video?.Effect}-D{video?.Duration}-I{video?.Interval}</div>
            }
                trigger="hover"
              >
                <Col id={video.VideoId}>
                  <Card
                    hoverable
                    style={{ borderRadius: "10px", width: "100%",marginBottom:'10px' }}
                    cover={
                      <iframe
                        id={video.VideoId}
                        src={`https://player.vimeo.com/video/${video.VideoId}`}
                        frameborder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowfullscreen
                        style={{
                          position: "inherit",
                          top: "0px",
                          left: "0px",
                          width: "329px",
                          height: "180px",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                        title=""
                      ></iframe>
                    }
                  >
                    <Meta
                      style={{ color: "white" }}
                      title={
                        <Row>
                          <Col
                            style={{
                              overflow: "hidden",
                              fontFamily: "Netto Regular",

                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                            span={20}
                          >
                            {video?.Procedure}
                          </Col>
                          {/* <Col style={{ marginLeft: "12px" }} span={2}>
                            {" "}
                            <Checkbox style={{borderColor:'black'}} value="A"></Checkbox>
                          </Col> */}
                        </Row>
                      }
                    />
                  </Card>
                </Col>
              </Popover>
            ))
                    }
        </Row>
        </div>

            </>
          ) : (
            <div  style={{ fontSize:'1.5vw'}}>
            <Row gutter={24}>
              <Col>
            <Card
              style={{
                background: "transparent",
                fontSize: "1.5vw",
                display: "flex",
                justifyContent: "center",
                padding: "150px",
                fontFamily: "Netto Light",
              }}
              className="chartTitle"
            >
              <center>
                <p>
                  Select a video and add it to your playlist by clicking the
                  heart-shaped icon linked to it. To search for videos, please
                  select your discipline and the intervention below first.
                </p>
              </center>
            </Card>
            </Col>
            </Row>
            </div>
          )
            }
    </Modal>
  );
}

export default PlaylistModal;
