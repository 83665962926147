import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Avatar } from "antd";
import FooterImg from "../../assets/images/footer-banner-2.jpg";
import { otherProdItems } from "../../config";
const styles = {
  header: {
    backgroundImage: `url(${FooterImg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
};

const Cardcontent = () => {
  const openURL = (url) => {
    window.open("https://" + url, "_blank");
  };
  return (
    <div style={styles.header}>
      <Row
        justify="center"
        style={{ padding: "2%", fontFamily: "Netto Regular" }}
      >
        {otherProdItems &&
          otherProdItems.length > 0 &&
          otherProdItems.map((product) => (
            <Col xs={24} sm={24} md={6} lg={6} style={{ margin: "1%" }}>
              <div style={{ textAlign: "center" }}>
                <Avatar
                  size={200}
                  onClick={() => openURL(product.url)}
                  src={product.imgSrc}
                />
              </div>

              <h3
                onClick={() => openURL(product.url)}
                style={{
                  textAlign: "center",
                  fontFamily: "Netto Thin",
                  color: "white",
                  cursor: "pointer",
                  fontSize: "2.8vw",
                }}
              >
                {product.header}
              </h3>
              <p
                style={{
                  color: "white",
                  fontWeight: "normal",
                  fontSize: "1.4vw",
                  fontFamily: "Netto Light",
                }}
              >
                {product.description}
              </p>
            </Col>
          ))}
      </Row>
    </div>
  );
};
export default Cardcontent;
