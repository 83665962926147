const initialState = {
  discipline: {
    loading: false,
    data: [],
  },
  intervention: {
    loading: false,
    data: [],
  },
  news: { loading: false, data: [] },
  playList: [],
};
export const DisciplineAndInterventionReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "GET_INTERVENTIONS":
      return {
        ...state,
        intervention: {
          ...state.intervention,
          loading: action.payload.loading,
        },
      };
    case "GET_DISCIPLINES":
      return {
        ...state,
        discipline: {
          ...state.discipline,
          loading: action.payload.loading,
        },
      };
    case "GET_DISCIPLINES_SUCCESS":
      return {
        ...state,
        discipline: {
          data: action.payload.data,
          loading: action.payload.loading,
        },
      };
    case "GET_INTERVENTIONS_SUCCESS":
      return {
        ...state,
        intervention: {
          data: action.payload.data,
          loading: action.payload.loading,
        },
      };
    case "GET_INTERVENTIONS_ERROR":
      return {
        ...state,
        intervention: {
          ...state.intervention,
          loading: action.payload.loading,
        },
      };
    case "GET_DISCIPLINES_ERROR":
      return {
        ...state,
        discipline: {
          ...state.discipline,
          loading: action.payload.loading,
        },
      };
    case "UPDATE_PLAY_LIST":
      return {
        ...state,
        playList: action.playList,
      };
    case "GET_NEWS":
      return {
        ...state,
        news: {
          ...state.news,
          loading: action.payload.loading,
        },
      };
    case "GET_NEWS_SUCCESS":
      return {
        ...state,
        news: {
          data: action.payload.data,
          loading: action.payload.loading,
        },
      };
    case "GET_NEWS_ERROR":
      return {
        ...state,
        news: {
          ...state.news,
          loading: action.payload.loading,
        },
      };
    default:
      return state;
  }
};
