import { Layout, Row, Col, Divider,Avatar } from "antd";
import "antd/dist/antd.css";
import Icon_1 from "../assets/images/item_562.png";
import Icon_3 from "../assets/images/item_573.png";
import Icon_2 from "../assets/images/item_585.png";
import Icon_4 from "../assets/images/item_598.png";
import { footerMenus } from "../config";
const { Footer } = Layout;

const Footeritem = () => {
  return (
    <Footer style={{ backgroundColor: "white" }}>
      <Row justify="center" style={{ marginTop: "3%" ,marginBottom:'3%'}}>
        {footerMenus &&
          footerMenus.length &&
          footerMenus.map((item) => (
            <Col xs={12} sm={12} md={4} lg={4}>
              <div
                onClick={() => window.open(item.url)}
                style={{ textAlign: "center", cursor: "pointer" }}
              >
                 <Avatar
                  size={100}
                  src={item.imgURL}
                  style={{marginBottom:'15px'}}
                />
                {/* <img
                  size={100}
                  alt="No Img"
                  src={item.imgURL}
                  style={{ marginBottom: "8px",borderRadius:'5px' }}
                /> */}
                <br />
                <span
                  style={{
                    fontFamily: "Netto black",
                    fontSize: "1.2vw",
                    fontWeight: "bold",
                  }}
                >
                  {item.name}
                </span>
              </div>
            </Col>
          ))}
      </Row>

      <Divider style={{ backgroundColor: "black" }} />
      <Row
        justify="center"
        style={{ fontSize: "1.3vw", fontFamily: "Netto Regular" }}
      >
        <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
          <div >
            <span>Erbe Elektromedizin GmbH</span> &nbsp; &nbsp;
            <span>Waldhoernlestrasse 17</span> &nbsp; &nbsp;
            <span>72072 Tuebingen, Germany </span>
          </div>
        </Col>
      </Row>
      {/* <br /> */}
      <Row
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        justify="center"
        style={{ marginTop: "20px" }}
      >
        <Col style={{ textAlign: "end" }}>
          <div
            style={{
              fontWeight: "bold",
              marginTop: "10px",
              fontSize: "20px",
              fontFamily: "Netto Regular",
            }}
          >
            FOLLOW US ON
          </div>
        </Col>
        <Col>
          {/* <span>
            <img size={40} src={Icon_1} onClick={() => window.open("https://de.erbe-med.com/de-en/")} />
          </span>{" "}
          &nbsp;
          <span>
            <img size={40} src={Icon_2} onClick={() => window.open("https://www.medical-videos.com/")} />
          </span>
         */}
          <span>
            <img size={40} src={Icon_3} onClick={() => window.open("https://www.linkedin.com/company/erbe-elektromedizin/mycompany/")} />
          </span>
          &nbsp;&nbsp;&nbsp;
          <span>
            <img size={40} src={Icon_4} onClick={() => window.open("https://www.youtube.com/user/erbemed")} />
          </span>
        </Col>
      </Row>
    </Footer>
  );
};
export default Footeritem;
