// REACT_APP_MAPBOX_TOKEN = "sk.eyJ1IjoibG9rZXNoODI0IiwiYSI6ImNrcWduOXV5OTAwZmMybm51NHZzODRlZHQifQ.vpu5Nq8z7An8n3ku9k2_5g"
//Do not change these config without proper permissions
import Product1 from "../src/assets/images/item_1087.png";
import Product2 from "../src/assets/images/item_1099.png";
import Product3 from "../src/assets/images/item_1110.png";
import newsTextBottomButtom from "../src/assets/images/Asset 2.png";
import newsImg1 from "../src/assets/images/item_2697.png";
import newsTopBtn1 from "../src/assets/images/item_1428.png";
import newsTopBtn2 from "../src/assets/images/icon.png";
import newsTopBtn3 from "../src/assets/images/item_1165.png";
import newsTopBtn4 from "../src/assets/images/item_1452.png";
import newsImg2 from "../src/assets/images/item_850.jpg";
import Event from "../src/assets/images/item_440.png";
import Medical from "../src/assets/images/item_464.png";
import Product from "../src/assets/images/item_488.png";
import Mobile from "../src/assets/images/item_512.png";
import Icon_1 from "../src/assets/images/item_562.png";

/*Do not change anything from line 14 to 17 it may cause serious issues. May god bless you.. */
export const BASEURL =
  "http://viovideo.erbe-med.com:3000";
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoibG9rZXNoODI0IiwiYSI6ImNrcWVzZWYwbTE3NGcyeG5tYnNpMnl5Y3QifQ.6WeSo1EcjVr9AIdZHCk3ww";

export const G_MAPS_API_KEY = "AIzaSyCNa-jOS24vQtuwlbQ--_KHfSB5wf53ayY"
// "AIzaSyDaXVDfeCtnpF8buvzBvZ3vSrmwfNFCt7A"  
/* Dont change till here it is serious issue */

export const teamPhone = "+49 (0) 7071–755-0";
export const teamEmail = "clinical-application@erbe-med.com";
export const teamAbtP1 =
  "We are a young, international team of experts dedicated to the application of our products in various disciplines.";
export const teamAbtP2 = `Our aim is to support and advise users regarding the daily
              challenges of surgical procedures. To this end, we maintain a
              global network and are in daily contact with our partners around
              the world to gain and pass on knowledge, in part through practical`;
export const otherProdItems = [
  {
    url: "vio.erbe-med.com",
    header: "vio.erbe-med.com",
    description: `VIO3 - precise effect settings, high reproducibility and homogenous
            tissue effects. Latest multiprocessor technology to power your
            performance`,
    imgSrc: Product1,
    atlTxt: "vio.erbe-med.com",
  },
  {
    url: "fiapc.erbe-med.com",
    header: "fiapc.erbe-med.com",
    description: `fiAPC - Plug-and play to coagulate or devitalize tissue during
            interventional endoscopic procedures. The integrated filter prevents
            your APC3 from contamination`,
    imgSrc: Product2,
    atlTxt: "fiapc.erbe-med.com",
  },
  {
    url: "hybrid.erbe-med.com",
    header: "hybrid.erbe-med.com",
    description: `Our proprietary hybrid technology makes advanced endoscopic
            procedures easier and faster. Learn more about the benefits of
            hybrid technology for you and your patients.`,
    imgSrc: Product3,
    atlTxt: "hybrid.erbe-med.com",
  },
];
/* the order should be the same for the news array*/
export const newsData = [
  {
    heading: "MEDICAL-VIDEOS.COM",
    subHeading: "Ovitias dolupti dolupti",
    description: `Ecabori sitem explam venapid quiatur sim quae pore optatiumernam
                cor sequiscium quo`,
    bottomBtnImg: newsTextBottomButtom,
    topBtnImg: newsTopBtn1,
    image: newsImg1,
  },
  {
    heading: "WORKSHOPS ON-DEMAND",
    subHeading: "Ovitias dolupti dolupti",
    description: `Ecabori sitem explam vendips anihic testemp erciunt apid quiatur
              sim quae pore optatiumernam cor sequiscium quo`,
    bottomBtnImg: newsTextBottomButtom,
    topBtnImg: newsTopBtn2,
    image: null,
  },
  {
    heading: "PODCAST",
    subHeading: "Medical insights",
    description: `Ecabori sitem explam vendips anihic testemp erciunt apid quiatur
              sim quae pore optatiumernam cor sequiscium quo`,
    bottomBtnImg: newsTextBottomButtom,
    topBtnImg: newsTopBtn3,
    image: null,
  },
  {
    heading: "PUBLICATIONS",
    subHeading: "Ovitias dolupti dolupti",
    description: `Ecabori sitem explam venapid quiatur sim quae pore optatiumernam
                cor sequiscium quo`,
    bottomBtnImg: newsTextBottomButtom,
    topBtnImg: newsTopBtn4,
    image: newsImg2,
  },
];

export const footerMenus = [
  {
    url: "https://erbe-med.com",
    name:'ERBE-MED.COM/EDUCATION',
    // name: "EVENTS",
    imgURL:Icon_1
    // imgURL: Event,
  },
  {
    url: "https://medical-videos.com",
    name:'VIO.ERBE-MED.COM',
    // name: "MEDICAL-VIDEOS.COM",
    imgURL:Product1,
    // imgURL: Medical,
  },
  {
    url: "https://erbe-med.com",
    name:'FIAPC.ERBE-MED.COM',
    // name: "PRODUCT CATALOG",
    imgURL:Product2,
    // imgURL: Product,
  },
  {
    url: "https://erbe-med.com",
    name:'HYBRID.ERBE-MED.COM',
    // name: "MOBILE APPS",
    imgURL:Product3,
    // imgURL: Product,
  },
];
