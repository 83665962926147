import React from "react";
import "antd/dist/antd.css";
import { Image, Row, Col } from "antd";
import Reg from "../../assets/images/item_1211.png";
import SubmissionIcon from "../../assets/images/Asset 3.png";
const SubmitVideo = () => {
  return (
    <div style={{ backgroundColor: "white", fontFamily: "Netto Regular" }}>
      <Row justify="center">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          style={{
            textAlign: "center",
            fontFamily: "Netto Thin",
            marginTop: "20px",
          }}
        >
          <span style={{ fontSize: "4vw", fontWeight: "800" }}>
            Submit a Video
          </span>
        </Col>
      </Row>
      <Row justify="center" style={{ margin: "1%",marginBottom:'1%' }}>
        <Col xs={24} sm={24} md={8} lg={8}>
          <span
            style={{
              fontSize: "2vw",
              fontWeight: 900,

              fontFamily: "Netto Regular",
            }}
          >
            REGISTER
          </span>
          <br />
          <span
            style={{
              fontSize: "2.4vw",
              fontWeight: 500,
              fontFamily: "Netto Regular",
            }}
          >
            ...here for upload
          </span>
          <div
            style={{
              fontSize: "1.7vw",
              marginTop: "2%",
              fontFamily: "Netto Regular",
            }}
          >
            <span style={{ fontWeight: 500 }}>
              Did you perform a procedure with VIO<sup>®</sup> 3 or APC 3, that
              you want to share with the global community? Follow the link to
              apply for contribution with your video.
            </span>
          </div>
          <Row style={{ backgroundColor: "lightgray",marginTop: "2%" }}>
            <Col
              span={12}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{ textAlign: "start", margin: "2px" }}
            >
              <div
                onClick={() =>
                  window.open(
                    "https://erbeelektromedizin.eu.qualtrics.com/jfe/form/SV_2fWg705ugocblhr",
                    "_blank"
                  )
                }
                style={{  cursor: "pointer",marginTop:'3px' }}
              >
                <span style={{ paddingLeft: "5px" }}>
                  <img src={SubmissionIcon} width="6%" height="6%" />
                </span>{" "}
                &nbsp; &nbsp;
                <span
                  style={{
                    fontFamily: "Netto Regular",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  Submission
                </span>
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} style={{ padding: "1%" }}>
          <img
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open(
                "https://erbeelektromedizin.eu.qualtrics.com/jfe/form/SV_2fWg705ugocblhr",
                "_blank"
              )
            }
            width="100%"
            src={Reg}
            height="100%"
            alt="Submit Video"
          />
        </Col>
      </Row>
    </div>
  );
};
export default SubmitVideo;
