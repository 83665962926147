const initialState = {
  matrixData: [],
  loading: false,
  videosList: {
    loading: false,
    data: [],
  },
  endoCUT: {
    loading: false,
    data: [],
    topConfigs: [],
  },
};

export const MatrixReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MATRIX":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "GET_MATRIX_SUCCESS":
      return {
        ...state,
        matrixData: action.payload.data,
        loading: action.payload.loading,
      };
    case "GET_MATRIX_ERROR":
      return {
        ...state,
        loading: action.payload.loading,
      };
    case "GET_MATRIXVIDEOS":
      return {
        ...state,
        videosList: {
          ...state.videosList,
          loading: action.payload.loading,
        },
      };
    case "GET_MATRIXVIDEOS_SUCCESS":
      return {
        ...state,
        videosList: {
          data: action.payload.data,
          loading: action.payload.loading,
        },
      };
    case "GET_MATRIXVIDEOS_ERROR":
      return {
        ...state,
        videosList: {
          ...state.videosList,
          loading: action.payload.loading,
        },
      };
    case "GET_ENDOCUT_DATA":
      return {
        ...state,
        endoCUT: {
          ...state.endoCUT,
          loading: action.payload.loading,
        },
      };

    case "GET_ENDOCUT_DATA_SUCCESS":
      return {
        ...state,
        endoCUT: {
          ...state.endoCUT,

          data: action.payload.data,
          loading: action.payload.loading,
        },
      };
    case "GET_ENDOCUT_DATA_ERROR":
      return {
        ...state,
        endoCUT: {
          ...state.endoCUT,
          loading: action.payload.loading,
        },
      };
    case "GET_ENDOCUT_TOP_CONFIG_DATA":
      return {
        ...state,
        endoCUT: {
          ...state.endoCUT,
          loading: action.payload.loading,
        },
      };

    case "GET_ENDOCUT_TOP_CONFIG_DATA_SUCCESS":
      return {
        ...state,
        endoCUT: {
          ...state.endoCUT,

          topConfigs: action.payload.data,
          loading: action.payload.loading,
        },
      };
    case "GET_ENDOCUT_TOP_CONFIG_DATA_ERROR":
      return {
        ...state,
        endoCUT: {
          ...state.endoCUT,
          loading: action.payload.loading,
        },
      };
    default:
      return state;
  }
};
