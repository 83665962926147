import React, { useEffect, useState } from "react";
import "./barchartstyle.css";
function Chart({
  selectedMode,
  primaryColor,
  matrixChartData,
  onEffectClick,
  isShowAll,
}) {
  const [svgHeight, setSVGHeight] = useState(400);
  useEffect(() => {
    if (
      matrixChartData &&
      matrixChartData.matrixData &&
      matrixChartData?.matrixData.length > 0
    )
      SetupGraph(selectedMode, matrixChartData.matrixData, isShowAll);
  }, [isShowAll, selectedMode, matrixChartData]);
  const WIDTH = 1075;
  const HEIGHT = svgHeight;
  const YAXISWIDTH = 200;
  const EMPTYBOXHEIGHT = 50;
  const BOXWIDTH = 85;
  const BOXHEIGHT = 100;
  const YAXISSTEPHEIGHT = 10;
  const CIRCLEDIAMETER = 7;
  const CIRCLEOFFSET = 10;
  const COAG_COLOR = "#00AEEF";
  const CUT_COLOR = "#FFD700";
  const APC_COLOR = "#67C18C";
  const ODDS = [
    0.1, 0.3, 0.5, 0.7, 0.9, 1.1, 1.3, 1.5, 1.7, 1.9, 2.1, 2.3, 2.5, 2.7, 2.9,
    3.1, 3.3, 3.5, 3.7, 3.9, 4.1, 4.3, 4.5, 4.7, 4.9, 5.1, 5.3, 5.5, 5.7, 5.9,
    6.1, 6.3, 6.5, 6.7, 6.9, 7.1, 7.3, 7.5, 7.7, 7.9, 8.1, 8.3, 8.5, 8.7, 8.9,
    9.1, 9.3, 9.5, 9.7, 9.9,
  ];
  const EVENS = [
    0.2, 0.4, 0.6, 0.8, 1.0, 1.2, 1.4, 1.6, 1.8, 2.0, 2.2, 2.4, 2.6, 2.8, 3.0,
    3.2, 3.4, 3.6, 3.8, 4.0, 4.2, 4.4, 4.6, 4.8, 5.0, 5.2, 5.4, 5.6, 5.8, 6.0,
    6.2, 6.4, 6.6, 6.8, 7.0, 7.2, 7.4, 7.6, 7.8, 8.0, 8.2, 8.4, 8.6, 8.8, 9.0,
    9.2, 9.4, 9.6, 9.8, 10.0,
  ];
  var SOLID_COLOR = "";
  const SetupGraph = (mode, data, isShowAll) => {
    var svg = document.getElementById("svgMatrixGraph");
    svg.innerHTML = "";
    svg.setAttribute("width", WIDTH);
    svg.setAttribute("height", HEIGHT);
    var filter_data = [];
    switch (mode) {
      case "CUT":
        SOLID_COLOR = CUT_COLOR;
        break;
      case "APC":
        SOLID_COLOR = APC_COLOR;
        break;
      case "COAG":
      default:
        SOLID_COLOR = COAG_COLOR;
        break;
    }

    if (isShowAll == true) {
      filter_data = data;
      setSVGHeight(`${filter_data.length - 1}00`);
    } else {
      filter_data = data.filter((d) => d.Total > 0);
      setSVGHeight(`${filter_data.length + 1}00`);
    }
    var yLineHeight = 0;
    var xLineWidth = 0;
    for (var i = 0; i < filter_data.length; i++) {
      if (filter_data[i].Total > 0) yLineHeight += BOXHEIGHT;
      else yLineHeight += EMPTYBOXHEIGHT;
    }

    xLineWidth = YAXISWIDTH + BOXWIDTH * 10;
    for (i = 0; i <= 10; i++) {
      svg.appendChild(
        GetYAxisLine(
          YAXISWIDTH + i * BOXWIDTH,
          0,
          YAXISWIDTH + i * BOXWIDTH,
          yLineHeight + YAXISSTEPHEIGHT
        )
      );
      if (i < 10) {
        for (var j = 1; j < 5; j++) {
          var xStepAxis = YAXISWIDTH + i * BOXWIDTH + j * (BOXWIDTH / 5);
          svg.appendChild(
            GetYAxisLine(
              xStepAxis,
              yLineHeight,
              xStepAxis,
              yLineHeight + YAXISSTEPHEIGHT
            )
          );
        }
      }
      if (i == 0) {
        svg.appendChild(
          GetXScaleText(
            YAXISWIDTH + i * BOXWIDTH - 10,
            yLineHeight + YAXISSTEPHEIGHT + 15,
            "0.1"
          )
        );
      } else {
        svg.appendChild(
          GetXScaleText(
            YAXISWIDTH + i * BOXWIDTH - 10,
            yLineHeight + YAXISSTEPHEIGHT + 15,
            i.toString() + ".0"
          )
        );
      }
    }
    let currentHeight = 0;
    for (i = 0; i < filter_data.length; i++) {
      if (filter_data[i].Total > 0) {
        svg.appendChild(
          GetXAxisLine(
            0,
            BOXHEIGHT + currentHeight,
            xLineWidth,
            BOXHEIGHT + currentHeight,
            1
          )
        );
        svg.appendChild(
          GetYScaleText(
            0,
            BOXHEIGHT + currentHeight - 10,
            filter_data[i].Mode,
            1
          )
        );
        for (var k = 0; k < ODDS.length; k++) {
          var ct = 0;
          var s1 = filter_data[i].Chart.filter((f) => f.x == ODDS[k]);
          var s2 = filter_data[i].Chart.filter((f) => f.x == EVENS[k]);
          if (s1.length > 0) ct += s1[0].y;
          if (s2.length > 0) ct += s2[0].y;
          if (ct > 5) ct = 5;

          if (ct > 0) {
            var r = (ODDS[k] * 10) % 10;
            var d = Math.floor((ODDS[k] * 10) / 10);
            var p = Math.floor(r / 2) + (r % 2);
            console.log(ODDS[k]);
            console.log(r);
            console.log(d);

            var circleXAxis =
              YAXISWIDTH + d * BOXWIDTH + p * (BOXWIDTH / 5) - CIRCLEOFFSET;
            for (var m = 0; m < ct; m++) {
              svg.appendChild(
                GetCircle(
                  circleXAxis,
                  BOXHEIGHT + currentHeight - 10 - ((BOXHEIGHT - 10) / 5) * m,
                  filter_data[i].Mode,
                  ODDS[k]
                )
              );
            }
          }
        }
        currentHeight += BOXHEIGHT;
      } else {
        svg.appendChild(
          GetXAxisLine(
            0,
            EMPTYBOXHEIGHT + currentHeight,
            xLineWidth,
            EMPTYBOXHEIGHT + currentHeight,
            0.5
          )
        );
        svg.appendChild(
          GetYScaleText(
            0,
            EMPTYBOXHEIGHT + currentHeight - 10,
            filter_data[i].Mode,
            0.5
          )
        );
        currentHeight += EMPTYBOXHEIGHT;
      }
    }
  };

  const GetYAxisLine = (x1, y1, x2, y2) => {
    var line = document.createElementNS("http://www.w3.org/2000/svg", "line");
    line.setAttribute("x1", x1);
    line.setAttribute("y1", y1);
    line.setAttribute("x2", x2);
    line.setAttribute("y2", y2);
    line.setAttribute("stroke", "white");
    line.setAttribute("stroke-width", 1);
    return line;
  };

  const GetXAxisLine = (x1, y1, x2, y2, o) => {
    var line = document.createElementNS("http://www.w3.org/2000/svg", "line");
    line.setAttribute("x1", x1);
    line.setAttribute("y1", y1);
    line.setAttribute("x2", x2);
    line.setAttribute("y2", y2);
    line.setAttribute("stroke", SOLID_COLOR);
    line.setAttribute("stroke-width", 1);
    line.setAttribute("stroke-opacity", o);
    return line;
  };

  const GetXScaleText = (x, y, t) => {
    var text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.setAttribute("x", x);
    text.setAttribute("y", y);
    text.setAttribute("font-size", 13);
    text.setAttribute("fill", "white");
    text.innerHTML = t;
    return text;
  };
  const CircleClick = (element) => {
    if (element.getAttribute("effect") && element.getAttribute("mode"))
      onEffectClick(
        element.getAttribute("effect"),
        element.getAttribute("mode")
      );
  };
  const GetYScaleText = (x, y, t, o) => {
    var text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.setAttribute("x", x);
    text.setAttribute("y", y);
    text.setAttribute("font-size", 13);
    text.setAttribute("fill", SOLID_COLOR);
    text.setAttribute("fill-opacity", o);
    text.innerHTML = t;
    return text;
  };

  const GetCircle = (x, y, m, e) => {
    var circle = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "circle"
    );
    circle.setAttribute("cx", x);
    circle.setAttribute("cy", y);
    circle.setAttribute("r", CIRCLEDIAMETER);
    circle.setAttribute("fill", SOLID_COLOR);
    circle.setAttribute("class", "dot-circle");
    circle.setAttribute("mode", m);
    circle.setAttribute("effect", e);
    circle.onclick = function () {
      CircleClick(this);
    };
    return circle;
  };

  return (
    <div
      style={{
        backgroundColor: "#19222b",
        display: "flex",
        justifyContent: "center",
        paddingLeft: "10px",
        paddingRight: "10px",
      }}
      className="chart"
    >
      <svg
        id="svgMatrixGraph"
        width={600}
        height={svgHeight}
        version="1.2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        class="graph"
        aria-labelledby="title"
        role="img"
      ></svg>
    </div>
  );
}

export default Chart;
