import axios from "axios";
import { BASEURL } from "../config";
export const getDisciplines = () => {
  let axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  return (dispatch) => {
    dispatch({
      type: "GET_DISCIPLINES",
      payload: {
        loading: true,
      },
    });
    axios
      .post(`${BASEURL}/disciplines`, axiosConfig)
      .then((disciplines) => {
        dispatch({
          type: "GET_DISCIPLINES_SUCCESS",
          payload: {
            data: disciplines?.data,
            loading: false,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "GET_DISCIPLINES_ERROR",
          payload: {
            loading: false,
          },
        });
      });
  };
};
export const getInterventions = (discipline) => {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  let formData = {
    Discipline: discipline,
  };
  return (dispatch) => {
    dispatch({
      type: "GET_INTERVENTIONS",
      payload: {
        loading: true,
      },
    });
    axios
      .post(`${BASEURL}/interventions`, formData, headers)
      .then((interventions) => {
        dispatch({
          type: "GET_INTERVENTIONS_SUCCESS",
          payload: {
            data: interventions?.data,
            loading: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_INTERVENTIONS_ERROR",
          payload: {
            loading: false,
          },
        });
        console.log(error);
      });
  };
};
export const getNews = () => {
  let axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };
  return (dispatch) => {
    dispatch({
      type: "GET_NEWS",
      payload: {
        loading: true,
      },
    });
    axios
      .post(`${BASEURL}/news`, axiosConfig)
      .then((data) => {
        dispatch({
          type: "GET_NEWS_SUCCESS",
          payload: {
            data: data?.data,
            loading: false,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "GET_NEWS_ERROR",
          payload: {
            loading: false,
          },
        });
      });
  };
};
export const clearInterventions = () => {
  return (dispatch) => {
    dispatch({
      type: "GET_INTERVENTIONS_SUCCESS",
      payload: {
        data: [],
        loading: false,
      },
    });
  };
};
export const updatePlayList = (data) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_PLAY_LIST",
      playList: data,
    });
  };
};
