import { combineReducers } from "redux";
import { DisciplineAndInterventionReducer } from "./DisciplineAndInterventions";
import { MapReducer } from "./map";
import { MatrixReducer } from "./matrix";
import { ModesReducer } from "./modes";

const rootReducer = combineReducers({
  DisciplineAndInterventionReducer: DisciplineAndInterventionReducer,
  MapReducer: MapReducer,
  MatrixReducer: MatrixReducer,
  ModesReducer:ModesReducer,
});
export default rootReducer;
