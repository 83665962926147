const initialState = {
  mapData: {
    loading: false,
    data: [],
  },
  videosList: {
    loading: false,
    data: [],
  },
};

export const MapReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MAP":
      return {
        ...state,
        mapData: {
          ...state.mapData,
          loading: action.payload.loading,
        },
      };
    case "GET_MAP_SUCCESS":
      return {
        ...state,
        mapData: {
          data: action.payload.data,
          loading: action.payload.loading,
        },
      };
    case "GET_MAP_ERROR":
      return {
        ...state,
        mapData: {
          ...state.mapData,
          loading: action.payload.loading,
        },
      };
    case "GET_MAPVIDEOS":
      return {
        ...state,
        videosList: {
          ...state.videosList,
          loading: action.payload.loading,
        },
      };
    case "GET_MAPVIDEOS_SUCCESS":
      return {
        ...state,
        videosList: {
          data: action.payload.data,
          loading: action.payload.loading,
        },
      };
    case "GET_MAPVIDEOS_ERROR":
      return {
        ...state,
        mapData: {
          ...state.videosList,
          loading: action.payload.loading,
        },
      };
    default:
      return state;
  }
};
