import React from "react";
import "antd/dist/antd.css";
import { Row, Col } from "antd";
// import Item_1 from "../../assets/images/item_1217.jpg";
import Item_2 from "../../assets/images/item_1218.jpg";
import Icon from "../../assets/images/Asset 2.png";
import { teamPhone, teamEmail, teamAbtP1, teamAbtP2 } from "../../config";
import './index.css';
const ClinicalTeam = () => {
  return (
    <div style={{ fontFamily: "Netto Regular" }} className="team" >
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          style={{
            textAlign: "center",
            fontFamily: "Netto Thin",
            marginTop: "2%",
          }}
        >
          <span style={{ fontSize: "4vw", fontWeight: "600" }}>
            Clinical Application Team
          </span>
        </Col>
      </Row>
      <Row justify="center" style={{ margin: "3%" }}>
        <Col xs={24} sm={24} md={10} lg={10}>
          <img width={"100%"} src={Item_2} />
          <div style={{ margin: "1%" }}>
            <Row style={{ fontFamily: "Netto Regular" }}>
            <Col xs={24} sm={24} md={6} lg={6} style={{textAlign:'center'}} >
                <span style={{ fontWeight: "bold", fontSize: "1.2vw" }}>
                Andrew Krauss
                </span>{" "}
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} style={{textAlign:'center'}} >
                <span style={{ fontWeight: "bold", fontSize: "1.2vw" }}>
                Alexander Pfäffle
                  
                </span>{" "}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={6}
                lg={6}
                style={{textAlign:'center'}}
              >
                <span style={{ fontWeight: "bold", fontSize: "1.2vw" }}>
                  Saskia Alber
                </span>{" "}
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} >
                <span style={{ fontWeight: "bold", fontSize: "1.2vw" }}>
                Dr. Niklas Frömmel
                </span>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={24} md={11} lg={11}>
          <div style={{ padding: "3%" }}>
            <p style={{ fontSize: "1.3vw", fontFamily: "Netto Regular" }}>
              {teamAbtP1}
            </p>
            <p style={{ fontSize: "1.3vw", fontFamily: "Netto Regular" }}>
              {teamAbtP2}
            </p>
            <span>
              <a
                href={`mailto:${teamEmail}`}
                style={{ color: "black", fontSize: "2vw" }}
              >
                <span>
                  <img src={Icon} width={30} />
                </span>{" "}
                {teamEmail}
              </a>
              <br />
              <span>
                <img src={Icon} width={30} />
              </span>{" "}
              <span style={{ fontSize: "2vw" }}>{teamPhone}</span>
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ClinicalTeam;
