import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/antd.css";
import ScatterChart from "../charts/ScatterChart";
import WorldMap from "../charts/WorldMap";
import {
  Row,
  Col,
  Button,
  Select,
  Card,
  Skeleton,
  Popover,
  Tooltip,
} from "antd";
import styled from "styled-components";
import HeaderImg from "../../assets/images/header-banner-1.jpg";
import "./index.css";
import CoagModal from "../modal/coagModal";
import {
  getDisciplines,
  getInterventions,
  updatePlayList,
  clearInterventions,
  getNews,
} from "../../Actions/DisciplineAndInterventions";
import { Spin, notification, Avatar } from "antd";
import {
  getMatrix_data,
  getMatrixvideos_data,
  getendoCUT_data,
  clearMatrixvideos_data,
  cleargetendoCUT_data,
  clearMatrix_data,
  getendoCUTTopConfigs,
} from "../../Actions/matrix";
import {
  getMap_data,
  getMapvideos_data,
  clearMap_data,
} from "../../Actions/map";
import { getModes, clearModes } from "../../Actions/modes";
import CustomBarChart from "../charts/Barchart";
import Icon from "../../assets/images/item_7936.png";
import MapIcon from "../../assets/images/map_icon.jpg";
import MatrixIcon from "../../assets/images/matrix_icon.jpg";
import { Image } from "antd";
const { Option } = Select;
const { Meta } = Card;
const styles = {
  header: {
    backgroundImage: `url(${HeaderImg})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    fontFamily: "Netto Regular",
  },
  selection_option: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "2%",
  },
};
const Banner = () => {
  const dispatch = useDispatch();
  const [isCreateModalVisible, setisCreateModalVisible] = useState(false);
  const [selectedDiscipline, setSlectedDiscipline] = useState(null);
  const [selectedIntro, setSelectedIntro] = useState(null);
  const [activeMap, setActiveMap] = useState("scatter");
  const [showChart, setShowChart] = useState(false);
  const [isShowAll, setIsShowAll] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedMode, setSelectedMode] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [matrixChartData, setMatrixChartData] = useState([]);
  const [modesList, setModesList] = useState([]);
  const [showMapVideos, setShowMapVideos] = useState(false);
  const [selectedEffect, setSelectedEffect] = useState("");
  const [showLeft, setShowLeft] = useState(true);
  const [showRight, setShowRight] = useState(true);
  const [selectedSubMode, setSelectedSubMode] = useState("");
  const [selectedEndoCut, setSelectedEndoCut] = useState("");
  const [showMatrix, setshowMatrix] = useState(false);
  const [selected_E, setSelected_E] = useState(4);
  const [selected_D, setSelected_D] = useState(4);
  const [selected_I, setSelected_I] = useState(10);
  const [SelectedModeVioCount, setSelectedModeVioCount] = useState(0);
  const endoCUT_Ref = useRef();
  const matrixData = useSelector((state) => state.MatrixReducer);
  const mapData = useSelector((state) => state.MapReducer);
  const disciplines = useSelector(
    (state) => state.DisciplineAndInterventionReducer.discipline.data
  );
  const interventions = useSelector(
    (state) => state.DisciplineAndInterventionReducer.intervention.data
  );
  const disciplinesLoading = useSelector(
    (state) => state.DisciplineAndInterventionReducer.discipline.loading
  );
  const interventionsLoading = useSelector(
    (state) => state.DisciplineAndInterventionReducer.intervention.loading
  );
  const modes = useSelector((state) => state.ModesReducer.modesData);
  useEffect(() => {
    let existingData = localStorage.getItem("PlayList");
    if (
      existingData !== "" ||
      existingData !== undefined ||
      existingData !== null
    ) {
      let parsedData = JSON.parse(existingData);
      dispatch(updatePlayList(parsedData));
    }
  }, []);
  useEffect(() => {
    dispatch(getDisciplines());
  }, []);
  useEffect(() => {
    if (modes?.data.length > 0) {
      let tmpModes = [];
      modes.data.map((mode) => {
        let md = {};
        if (mode.Mode === "COAG") {
          md["Mode"] = mode.Mode;
          md["VioCount"] = mode.VioCount;
          md["bgColor"] = "#00AEEF";
        } else if (mode.Mode === "CUT") {
          md["Mode"] = mode.Mode;
          md["VioCount"] = mode.VioCount;
          md["bgColor"] = "#FFD700";
        } else if (mode.Mode === "endoCUT") {
          md["Mode"] = mode.Mode;
          md["VioCount"] = mode.VioCount;
          md["bgColor"] = "#FFD700";
        } else if (mode.Mode === "APC") {
          md["Mode"] = mode.Mode;
          md["VioCount"] = mode.VioCount;
          md["bgColor"] = "#67C18C";
        } else {
          md["Mode"] = mode.Mode;
          md["VioCount"] = mode.VioCount;
          md["bgColor"] = "#40a9ff";
        }
        tmpModes.push(md);
      });
      setModesList(tmpModes);
    }
  }, [modes]);
  useEffect(() => {
    getChartData(selectedMode);
  }, [selectedMode]);
  const clearDisciplineAndIntroRedux = () => {
    dispatch(clearInterventions());
    dispatch(clearMatrixvideos_data());
    dispatch(cleargetendoCUT_data());
    dispatch(clearMatrix_data());
    dispatch(clearMap_data());
    dispatch(clearModes());
    setShowChart(false);
    setSelectedCity("");
    setSelectedCountry("");
    setSelectedMode("");
    setModesList([]);
    setShowMapVideos(false);
    setSelectedEffect("");
    setSelectedSubMode("");
    setSelectedEndoCut("");
    setSelected_E(4);
    setSelected_D(4);
    setSelected_I(10);
    setSelectedModeVioCount(0);
  };

  const onEffectClick = (effect, submode) => {
    if (
      effect &&
      submode &&
      selectedIntro !== null &&
      selectedDiscipline !== null &&
      selectedMode !== null
    ) {
      dispatch(
        getMatrixvideos_data(
          selectedIntro,
          selectedDiscipline,
          selectedMode,
          submode,
          effect
        )
      );
      setSelectedSubMode(submode);
      setSelectedEffect(effect);
      setisCreateModalVisible(true);
    }
  };
  /**
   * Handles the change in interversion
   * @param {*} value 
   */
  const handleInterventionChange = (value) => {
    console.log('Interversion is changes')
    setSelectedIntro(value);
    setShowChart(true);
    setshowMatrix(false);
    setSelectedMode("");
    dispatch(clearMatrixvideos_data());
    dispatch(cleargetendoCUT_data());
    dispatch(clearMatrix_data());
    dispatch(getModes(value, selectedDiscipline));
  };
  const handleDisciplineChange = (value) => {
    setSelectedIntro(null);
    setSlectedDiscipline(value);
    setshowMatrix(false);
    setModesList([]);
    setShowChart(false);
    setSelectedMode("");
    dispatch(getInterventions(value));
  };
  const toggleMap = () => {
    if (activeMap === "scatter") {
      if (
        selectedDiscipline !== null &&
        selectedIntro !== null &&
        selectedMode !== ""
      ) {
        dispatch(getMap_data(selectedDiscipline, selectedIntro, selectedMode));
        setActiveMap("world");
      }
    } else {
      if (
        selectedDiscipline !== null &&
        selectedIntro !== null &&
        selectedMode !== ""
      ) {
        dispatch(
          getMatrix_data(selectedDiscipline, selectedIntro, selectedMode)
        );
        setActiveMap("scatter");
      }
    }
  };
  const getChartData = (mode) => {
    if (
      selectedDiscipline !== null &&
      selectedIntro !== null &&
      mode !== "" &&
      mode !== "endoCUT"
    ) {
      dispatch(getMatrix_data(selectedDiscipline, selectedIntro, mode));
    }
  };
  const checkScroll = () => {
    if (document.getElementById("endoCUT_Videos") !== null) {
      if (
        parseInt(document.getElementById("endoCUT_Videos").scrollLeft) ===
        document.getElementById("endoCUT_Videos").scrollWidth -
          document.getElementById("endoCUT_Videos").offsetWidth
      ) {
        setShowRight(false);
      } else {
        setShowRight(true);

        document.getElementById("endoCUT_Videos").scrollLeft += 325;
      }
      if (document.getElementById("endoCUT_Videos").scrollLeft == 0) {
        setShowLeft(false);
      } else {
        setShowLeft(true);

        document.getElementById("endoCUT_Videos").scrollLeft -= 325;
      }
    }
  };
  const handleNextScroll = () => {
    setShowLeft(true);

    if (
      parseInt(document.getElementById("endoCUT_Videos").scrollLeft) ===
      document.getElementById("endoCUT_Videos").scrollWidth -
        document.getElementById("endoCUT_Videos").offsetWidth
    ) {
      setShowRight(false);
    } else {
      setShowRight(true);

      document.getElementById("endoCUT_Videos").scrollLeft += 325;
    }
  };
  const handlePrevScroll = () => {
    setShowRight(true);
    if (document.getElementById("endoCUT_Videos").scrollLeft == 0) {
      setShowLeft(false);
    } else {
      setShowLeft(true);

      document.getElementById("endoCUT_Videos").scrollLeft -= 325;
    }
  };
  const getEndocutData = () => {
    checkScroll();

    dispatch(
      getendoCUT_data(
        selectedDiscipline,
        selectedIntro,
        `endoCUT ${selectedEndoCut}`,
        selected_E,
        selected_D,
        selected_I
      )
    );
  };
  const getendoData = (mode, e, d, i) => {
    setSelected_E(e);
    setSelected_D(d);
    setSelected_I(i);
    setSelectedEndoCut(mode);

    dispatch(
      getendoCUT_data(
        selectedDiscipline,
        selectedIntro,
        `endoCUT ${mode}`,
        e,
        d,
        i
      )
    );
  };
  const toggleCoagModal = () => {
    setisCreateModalVisible(!isCreateModalVisible);
  };
  const handleMapMarkerClick = (city, country) => {
    setShowMapVideos(true);
    setSelectedCity(city);
    setSelectedCountry(country);
    dispatch(
      getMapvideos_data(
        selectedIntro,
        selectedDiscipline,
        selectedMode,
        country,
        city
      )
    );
  };
  /**
   * Removes the video clicked from the playlist
   * @param {*} video 
   * @param {*} clickedVidoId 
   */
  const removeFromPlaylist = (video, clickedVidoId) => {
    let existingData = localStorage.getItem("PlayList");
    if (
      existingData !== "" &&
      existingData !== undefined &&
      existingData != null
    ) {
      let playlistVideos = JSON.parse(localStorage.getItem("PlayList"));
      let updatedPlayList = [];
      playlistVideos.map((item) => {
        if (item.VideoId !== video.VideoId) {
          updatedPlayList.push(item);
        }
      });
      let args = {
        message: "Video removed",
        description: "Video is removed from your playlist !!",
        duration: 3,
      };
      dispatch(updatePlayList(updatedPlayList));
      notification.success(args);
      localStorage.setItem("PlayList", JSON.stringify(updatedPlayList));
      document.getElementById(clickedVidoId).style.color = "white";
    }
  };
  /**
   * Checks if the video by id is in the playlist reducer and localstorage
   * @param {*} video 
   * @returns 
   */
  const checkIfVideoInPlaylist = (video) => {
    let isInPlaylist = false;
    let existingData = localStorage.getItem("PlayList");
    if (
      existingData != "" &&
      existingData != undefined &&
      existingData != null
    ) {
      let playlistVideos = JSON.parse(localStorage.getItem("PlayList"));
      playlistVideos.map((item) => {
        if (item.VideoId == video.VideoId) {
          isInPlaylist = true;
          return true;
        }
      });
      return isInPlaylist;
    }
  };
  /**
   * Adds the video clicked to the playlist also updates the local storage
   * @param {*} video 
   * @param {*} clickedVidoId 
   */
  const addToPlaylist = (video, clickedVidoId) => {
    let existingData = localStorage.getItem("PlayList");
    if (
      existingData === "" ||
      existingData === undefined ||
      existingData == null
    ) {
      localStorage.setItem("PlayList", JSON.stringify([video]));
      let args = {
        message: "Added to playlist",
        description:
          "Video is added to your playlist, you can view from playlist option in top menu bar",
        duration: 3,
      };
      dispatch(updatePlayList([video]));
      notification.success(args);
      document.getElementById(clickedVidoId).style.color = "red";
    } else {
      let existingPlayList = JSON.parse(localStorage.getItem("PlayList"));
      let index = existingPlayList.map((o) => o.VideoId).indexOf(video.VideoId);
      if (index <= -1) {
        existingPlayList = [...existingPlayList, video];
        localStorage.setItem("PlayList", JSON.stringify(existingPlayList));
        dispatch(updatePlayList(existingPlayList));
        let args = {
          message: "Added to playlist",
          description:
            "Video is added to your playlist, you can view from playlist option in top menu bar",
          duration: 3,
        };
        notification.success(args);
        document.getElementById(clickedVidoId).style.color = "red";
      } else {
        let errargs = {
          message: "Can not add to playlist",
          description:
            "Video is already added to your playlist, you can view from playlist option in top menu bar",
          duration: 3,
        };
        notification.error(errargs);
      }
    }
  };
  const getTopConfigsEndoCUT = (mode) => {
    if (selectedDiscipline !== null && selectedIntro !== null && mode !== "") {
      dispatch(
        getendoCUTTopConfigs(
          selectedDiscipline,
          selectedIntro,
          `endoCUT ${mode}`
        )
      );
    }
  };
  const FlexBox = styled.div`
    margin: 2px;
    // padding: 20px;
    border: 1px solid #00a76d;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
      margin: 20px;
    }
  `;
  return (
    <React.Fragment>
      <CoagModal
        isVisible={isCreateModalVisible}
        handleOk={toggleCoagModal}
        handleCancel={toggleCoagModal}
        matrixData={matrixData}
        addToPlaylist={addToPlaylist}
        checkIfVideoInPlaylist={checkIfVideoInPlaylist}
        removeFromPlaylist={removeFromPlaylist}
        Icon={Icon}
        selectedEffect={selectedEffect}
        selectedSubMode={selectedSubMode}
      />
      <div style={styles.header}>
        <Row justify="center">
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            style={{ textAlign: "center", marginTop: "1%" }}
          >
            <span
              style={{
                color: "white",
                fontFamily: "Netto Thin",
                fontSize: "4.5vw",
              }}
            >
              VIO<sup>®</sup> On Video
            </span>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12}>
            <h3
              // className="p4"
              style={{
                fontSize: "2.1vw",
                color: "white",
                textAlign: "center",
                fontFamily: "Netto Thin",
                fontWeight: "bold",
              }}
            >
              Choose your discipline and intervention to see performance of VIO
              <sup>®</sup> 3 and APC 3 in a range of settings
            </h3>
          </Col>
        </Row>

        <Row justify="center" style={{ color: "white" }}>
          <Col xs={24} sm={24} md={9} lg={9} style={{ padding: "1%" }}>
            <p
              style={{
                fontFamily: "Netto Thin",
                fontWeight: "bold",
                fontSize: "1.4vw",
              }}
            >
              Prepare for your next procedure, see the settings of worldwide
              renowned experts or share your own experiences with colleagues
              from around the globe. Join the community and become a part of VIO
              <sup>®</sup> on video.
            </p>
          </Col>
          <Col xs={24} sm={24} md={9} lg={9} style={{ padding: "1%" }}>
            <p
              style={{
                fontFamily: "Netto Thin",
                fontSize: "1.5vw",
                fontWeight: "bold",
              }}
            >
              VIO<sup>®</sup> on video is a database of videoclips from
              interventions of various disciplines. Select your discipline and a
              specific intervention to learn how to achieve different tissue
              effects with different settings.
            </p>
          </Col>
        </Row>

        <div className="disciplineselctor" style={styles.selection_option}>
          <Row justify="center" gutter={24}>
            <Col xs={24} sm={24} md={6} lg={6}>
              <FlexBox
                style={{ backgroundColor: "#00A76D", borderRadius: "5px" }}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="DISCIPLINE"
                  value={selectedDiscipline}
                  loading={disciplinesLoading}
                  size="small"
                  onChange={handleDisciplineChange}
                >
                  {disciplines &&
                    disciplines.map((item) => (
                      <Option key={item}>{item}</Option>
                    ))}
                </Select>
              </FlexBox>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6}>
              <FlexBox
                style={{ backgroundColor: "#00A76D", borderRadius: "5px" }}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="INTERVENTION"
                  size="small"
                  loading={interventionsLoading}
                  disabled={selectedDiscipline !== null ? false : true}
                  value={selectedIntro}
                  onChange={handleInterventionChange}
                >
                  {interventions &&
                    interventions.length > 0 &&
                    interventions[0].Interventions.map((item) => (
                      <Option key={item}>{item}</Option>
                    ))}
                </Select>
              </FlexBox>
            </Col>
          </Row>
        </div>
      </div>
      {showChart && (
        <div
          className="chartBG"
          style={{
            paddingLeft: activeMap === "scatter" ? "7vw" : 0,
            paddingRight: activeMap === "scatter" ? "7vw" : 0,
          }}
        >
          <div className="chartTopBtns">
            {modes.loading === true ? (
              <Row style={{ display: "flex" }} gutter={25}>
                <Col lg={4} sm={24} xs={24} md={12} xxl={6}>
                  <Skeleton.Input
                    active={true}
                    style={{ width: 200 }}
                    size={"large"}
                  ></Skeleton.Input>
                </Col>
                <Col lg={4} sm={24} xs={24} md={12} xxl={6}>
                  <Skeleton.Input
                    active={true}
                    style={{ width: 200 }}
                    size={"large"}
                  ></Skeleton.Input>
                </Col>
                <Col lg={4} sm={24} xs={24} md={12} xxl={6}>
                  <Skeleton.Input
                    active={true}
                    style={{ width: 200 }}
                    size={"large"}
                  ></Skeleton.Input>
                </Col>
                <Col lg={4} sm={24} xs={24} md={12} xxl={6}>
                  <Skeleton.Input
                    active={true}
                    style={{ width: 200 }}
                    size={"large"}
                  ></Skeleton.Input>
                </Col>
              </Row>
            ) : (
              <Row gutter={30}>
                {modesList.map((mode) => (
                  <Col xs={24} sm={24} md={6} lg={6}>
                    <Button
                      style={{
                        backgroundColor: mode.bgColor,
                        color: "black",
                        border: mode.bgColor,
                      }}
                      className={
                        selectedMode === mode.Mode
                          ? "highlight modeBtn"
                          : "modeBtn"
                      }
                      type="primary"
                      block
                      onClick={() => {
                        setActiveMap("scatter");
                        setIsShowAll(false);
                        setSelectedMode(mode.Mode);
                        setSelectedModeVioCount(mode.VioCount);
                        setPrimaryColor(mode.bgColor);
                        getChartData(mode.Mode);
                        setshowMatrix(true);
                      }}
                    >
                      {mode.Mode} [{mode.VioCount}]
                    </Button>
                  </Col>
                ))}
              </Row>
            )}
          </div>

          {showMatrix === true && (
            <>
              {selectedMode === "endoCUT" && (
                <>
                  <Row
                    style={{
                      paddingLeft: activeMap === "scatter" ? 0 : "15%",
                      paddingRight: activeMap === "scatter" ? 0 : "15%",
                    }}
                    className="endoCutCards"
                    gutter={24}
                  >
                    <Col lg={12} sm={24} xs={24} md={12} xxl={12}>
                      <Card
                        style={{ height: "100%", borderRadius: "10px" }}
                        title="endoCUT Q"
                        headStyle={{
                          border:
                            selectedEndoCut === "Q" ? "5px solid white" : "",
                        }}
                        onClick={() => {
                          setSelectedEndoCut("Q");
                          setSelected_E(4);
                          setSelected_D(4);
                          setSelected_I(10);
                          getEndocutData("Q");
                          getTopConfigsEndoCUT("Q");
                        }}
                      >
                        endoCUT Q fractionates the cutting process into
                        controlled cutting and coagulation intervals, e.g. for
                        endoscopic polypectomy using a snare. Cutting and
                        coagulation cycles can be adjusted individually to
                        minimize the risks connected with polypectomies, such as
                        bleeding if coagulation is insufficient, or perforation
                        if coagulation is too intense. The cutting voltages goes
                        through an incremental increase over the first three
                        cycles.
                      </Card>
                    </Col>
                    <Col lg={12} sm={24} xs={24} md={12} xxl={12}>
                      <Card
                        style={{ height: "100%", borderRadius: "10px" }}
                        title="endoCUT I"
                        headStyle={{
                          border:
                            selectedEndoCut === "I" ? "5px solid white" : "",
                        }}
                        onClick={() => {
                          setSelectedEndoCut("I");
                          setSelected_E(4);
                          setSelected_D(4);
                          setSelected_I(10);
                          getEndocutData("I");
                          getTopConfigsEndoCUT("I");
                        }}
                      >
                        The fractionated cutting mode endoCUT I is used for
                        papillotomies and other applications involving a needle
                        or wire instrument (e.g. mucosal incision in ESD or
                        POEM). The cutting and coagulation cycles can be
                        adjusted individually to minimize the risks, such as the
                        zipper effect (uncontrolled incision into the papilla).
                        The cutting voltages remains at the same level for all
                        cutting cycles.
                      </Card>
                    </Col>
                  </Row>
                  <br />
                </>
              )}
              {activeMap === "scatter" ? (
                <div>
                  <div className="chartArea">
                    {selectedMode !== "" &&
                      (matrixData.loading === true ? (
                        <Spin tip="Loading.." size="large">
                          <p className="showAllModesBtn">SHOW ALL MODES</p>
                          <ScatterChart
                            selectedMode={selectedMode}
                            primaryColor={primaryColor}
                            toggleCoagModal={toggleCoagModal}
                            matrixChartData={matrixChartData}
                            isShowAll={isShowAll}
                          />
                        </Spin>
                      ) : selectedMode === "endoCUT" ||
                        SelectedModeVioCount !== 0 ? (
                        <>
                          {selectedMode !== "endoCUT" && (
                            <Button
                              style={{
                                padding: "0px",
                                fontSize: "25px",
                                fontFamily: "Netto Regular",
                                fontWeight: 800,
                                height: 50,
                                border: primaryColor,
                                borderRadius: "10px",
                                backgroundColor: primaryColor,
                                // backgroundColor: "#00AEEF"
                              }}
                              block
                            >
                              {selectedMode}
                            </Button>
                          )}
                          <Card className="chartTitle">
                            {selectedMode !== "endoCUT" ? (
                              <>
                                <p
                                  onClick={() => setIsShowAll(!isShowAll)}
                                  className="showAllModesBtn"
                                >
                                  {isShowAll ? "HIDE MODES" : "SHOW ALL MODES"}
                                </p>
                                <ScatterChart
                                  selectedMode={selectedMode}
                                  primaryColor={primaryColor}
                                  toggleCoagModal={toggleCoagModal}
                                  matrixChartData={matrixData}
                                  onEffectClick={onEffectClick}
                                  isShowAll={isShowAll}
                                />
                              </>
                            ) : (
                              <>
                                {selectedEndoCut !== "" && (
                                  <>
                                    <Row
                                      style={{
                                        color: "white",
                                        display: "flex",
                                        justifyContent: "center",
                                        backgroundColor: "#333023",
                                        fontSize: 30,
                                        fontFamily: "Netto Thin",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <Col>endoCUT {selectedEndoCut}</Col>
                                    </Row>
                                    <CustomBarChart
                                      selectedMode={selectedEndoCut}
                                      selected_E={selected_E}
                                      selected_D={selected_D}
                                      selected_I={selected_I}
                                      setSelected_D={setSelected_D}
                                      setSelected_E={setSelected_E}
                                      setSelected_I={setSelected_I}
                                      getendoData={getendoData}
                                      getEndocutData={getEndocutData}
                                      endoCUT_Ref={endoCUT_Ref}
                                    />
                                    {matrixData.endoCUT?.topConfigs.length >
                                      0 && (
                                      <>
                                        <Row
                                          style={{
                                            color: "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            backgroundColor: "#333023",
                                            fontSize: 30,
                                            fontFamily: "Netto Thin",
                                            paddingBottom: "10px",
                                          }}
                                        >
                                          <Col>TOP 5</Col>
                                        </Row>
                                        <Row
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            backgroundColor: "#333023",
                                            paddingBottom: "10px",
                                            marginLeft: "0px",
                                            marginRight: "0px",
                                          }}
                                          gutter={30}
                                        >
                                          {matrixData.endoCUT?.topConfigs.map(
                                            (item) => (
                                              <Col
                                                xs={24}
                                                sm={24}
                                                md={4}
                                                lg={4}
                                              >
                                                <Button
                                                  style={{
                                                    backgroundColor: "#FFD700",
                                                    color: "black",
                                                    border: "#FFD700",
                                                  }}
                                                  className={
                                                    selected_D === item.D &&
                                                    selected_E === item.E &&
                                                    selected_I === item.I
                                                      ? "highlight modeBtn"
                                                      : "modeBtn"
                                                  }
                                                  type="primary"
                                                  block
                                                  onClick={() => {
                                                    setSelected_E(item.E);
                                                    setSelected_D(item.D);
                                                    setSelected_I(item.I);
                                                    getendoData(
                                                      selectedEndoCut,
                                                      item.E,
                                                      item.D,
                                                      item.I
                                                    );
                                                  }}
                                                >
                                                  {item.Text}
                                                </Button>
                                              </Col>
                                            )
                                          )}
                                        </Row>
                                        <div className="endoCut_Videos">
                                          <Row
                                            id="endoCUT_Videos"
                                            style={{
                                              overflowY: "auto",
                                              paddingTop: "20px",
                                              backgroundColor: "#2f2b2b",
                                              paddingBottom: "20px",
                                            }}
                                            bodyStyle={{
                                              backgroundColor: "white",
                                            }}
                                            gutter={30}
                                          >
                                            {matrixData.endoCUT.loading ===
                                            true ? (
                                              <span className="loader">
                                                <Col>
                                                  <Card
                                                    style={{
                                                      width: 300,
                                                      marginTop: 16,
                                                    }}
                                                  >
                                                    <Skeleton
                                                      loading={true}
                                                      avatar
                                                      active
                                                    >
                                                      <Meta
                                                        avatar={
                                                          <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                                                        }
                                                        title="Card title"
                                                        description="This is the description"
                                                      />
                                                    </Skeleton>
                                                  </Card>
                                                </Col>
                                              </span>
                                            ) : matrixData.endoCUT.data.length >
                                              0 ? (
                                              matrixData.endoCUT.data.map(
                                                (video, idx) => (
                                                  <Popover
                                                    content={
                                                      <div>
                                                      <p style={{fontWeight:'bold'}}>Procedure </p>
                                                        {video?.Procedure}
                                                      </div>
                                                    }
                                                    title={
                                                      <div>
                                                        {video?.Mode} - E{video?.Effect}-D{video?.Duration}-I{video?.Interval}
                                                      </div>
                                                    }
                                                    trigger="hover"
                                                  >
                                                    <Col key={idx}>
                                                      <Card
                                                        hoverable
                                                        bordered={true}
                                                        style={{
                                                          borderRadius: "10px",
                                                        }}
                                                        cover={
                                                          <iframe
                                                            id={video.VideoId}
                                                            src={`https://player.vimeo.com/video/${video.VideoId}`}
                                                            frameborder="0"
                                                            allow="autoplay; fullscreen; picture-in-picture"
                                                            allowfullscreen
                                                            style={{
                                                              position:
                                                                "inherit",
                                                              top: "0px",
                                                              left: "0px",
                                                              width: "329px",
                                                              height: "180px",
                                                              borderTopLeftRadius:
                                                                "10px",
                                                              borderTopRightRadius:
                                                                "10px",
                                                            }}
                                                            title=""
                                                          ></iframe>
                                                        }
                                                      >
                                                        <Meta
                                                          style={{
                                                            color: "white",
                                                          }}
                                                          title={
                                                            <Row>
                                                              <Col
                                                                style={{
                                                                  overflow:
                                                                    "hidden",
                                                                  fontFamily:
                                                                    "Netto Regular",
                                                                  // marginTop: "8px",
                                                                  textOverflow:
                                                                    "ellipsis",
                                                                  whiteSpace:
                                                                    "nowrap",
                                                                }}
                                                                span={20}
                                                              >
                                                                {
                                                                  video?.Procedure
                                                                }
                                                              </Col>
                                                              <Col
                                                                style={{
                                                                  marginLeft:
                                                                    "12px",
                                                                }}
                                                                span={3}
                                                              >
                                                                {" "}
                                                                <span
                                                                  style={{
                                                                    fontSize:
                                                                      "25px",
                                                                  }}
                                                                  onClick={() =>
                                                                    checkIfVideoInPlaylist(
                                                                      video
                                                                    ) == true
                                                                      ? removeFromPlaylist(
                                                                          video,
                                                                          `video_playlistBtn_${idx}_mode`
                                                                        )
                                                                      : addToPlaylist(
                                                                          video,
                                                                          `video_playlistBtn_${idx}_mode`
                                                                        )
                                                                  }
                                                                >
                                                                  <i
                                                                    class="fas fa-heart"
                                                                    id={`video_playlistBtn_${idx}_EndoCUT`}
                                                                    style={{
                                                                      color:
                                                                        checkIfVideoInPlaylist(
                                                                          video
                                                                        ) ==
                                                                        true
                                                                          ? "red"
                                                                          : "white",
                                                                    }}
                                                                  ></i>
                                                                </span>
                                                              </Col>
                                                            </Row>
                                                          }
                                                        />
                                                      </Card>
                                                    </Col>
                                                  </Popover>
                                                )
                                              )
                                            ) : (
                                              <Card
                                                style={{
                                                  height: "100px",
                                                  background: "transparent",
                                                  color: "white",
                                                  fontSize: "30px",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  padding: "150px",
                                                  fontFamily: "Netto Light",
                                                }}
                                                className="chartTitle"
                                              >
                                                <center>
                                                  <p>
                                                    {" "}
                                                    For the selected effect,
                                                    duration and interval no
                                                    videos of the intervention
                                                    you have selected have been
                                                    submitted.
                                                  </p>
                                                </center>
                                              </Card>
                                            )}
                                          </Row>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Card>
                        </>
                      ) : (
                        <Card
                          style={{
                            height: "400px",
                            background: "transparent",
                            color: "white",
                            fontSize: "2vw",
                            display: "flex",
                            justifyContent: "center",
                            padding: "100px",
                            fontFamily: "Netto Light",
                          }}
                          className="chartTitle"
                        >
                          <p>
                            {" "}
                            No Data Found for the selected mode, Please try
                            other modes.
                          </p>
                        </Card>
                      ))}
                  </div>
                </div>
              ) : (
                <div>
                  {mapData.mapData.loading === true ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "75px",
                      }}
                    >
                      <Spin tip="Loading.." size="large"></Spin>
                    </div>
                  ) : (
                    <Row style={{ justifyContent: "center", height: "63vh" }}>
                      <Col span={showMapVideos ? 17 : 24}>
                        <WorldMap
                          mapData={mapData}
                          showMapVideos={showMapVideos}
                          handleMapMarkerClick={handleMapMarkerClick}
                          width={showMapVideos ? "70vw" : "98vw"}
                        />
                        <br />
                      </Col>
                      {showMapVideos === true && (
                        <Col span={6}>
                          <div className="world_videos">
                            <Row>
                              <Col className="selectedCountry">
                                {selectedCountry}, {selectedCity}
                              </Col>
                            </Row>
                            {mapData.videosList.loading === true ? (
                              <>
                                <Skeleton />
                                <br />
                                <Skeleton />
                              </>
                            ) : (
                              mapData.videosList.data.map((video, idx) => (
                                <Popover
                                  content={
                                    <div>
                                      <p style={{ fontWeight: "bold" }}>
                                        Procedure{" "}
                                      </p>
                                      {video?.Procedure}
                                    </div>
                                  }
                                  title={
                                    <div>
                                      {video?.Mode} - {video?.Effect}
                                    </div>
                                  }
                                  trigger="hover"
                                >
                                  <Row key={idx}>
                                    <Col>
                                      <Card
                                        hoverable
                                        style={{ borderRadius: "10px" }}
                                        cover={
                                          <iframe
                                            id={video.VideoId}
                                            src={`https://player.vimeo.com/video/${video.VideoId}`}
                                            frameborder="0"
                                            allow="autoplay; fullscreen; picture-in-picture"
                                            allowfullscreen
                                            style={{
                                              position: "inherit",
                                              top: "0px",
                                              left: "0px",
                                              width: "329px",
                                              height: "180px",
                                              borderTopLeftRadius: "10px",
                                              borderTopRightRadius: "10px",
                                            }}
                                            title=""
                                          ></iframe>
                                        }
                                      >
                                        <Meta
                                          style={{ color: "white" }}
                                          title={
                                            <Row>
                                              <Col
                                                style={{
                                                  overflow: "hidden",
                                                  fontFamily: "Netto Regular",
                                                  marginTop: "8px",
                                                  textOverflow: "ellipsis",
                                                  whiteSpace: "nowrap",
                                                }}
                                                span={20}
                                              >
                                                {video?.Procedure}
                                              </Col>
                                              <Col
                                                style={{ marginLeft: "12px" }}
                                                span={3}
                                              >
                                                {" "}
                                                <span
                                                  style={{ fontSize: "25px" }}
                                                  onClick={() =>
                                                    checkIfVideoInPlaylist(
                                                      video
                                                    ) == true
                                                      ? removeFromPlaylist(
                                                          video,
                                                          `video_playlistBtn_${idx}_mode`
                                                        )
                                                      : addToPlaylist(
                                                          video,
                                                          `video_playlistBtn_${idx}_mode`
                                                        )
                                                  }
                                                >
                                                  <i
                                                    class="fas fa-heart"
                                                    id={`video_playlistBtn_${idx}_mode`}
                                                    style={{
                                                      color:
                                                        checkIfVideoInPlaylist(
                                                          video
                                                        ) == true
                                                          ? "red"
                                                          : "white",
                                                    }}
                                                  ></i>
                                                </span>
                                                {/* <img
                                src={Icon}
                                alt={"Add to playlist"}
                                className="img-responsive"
                              /> */}
                                              </Col>
                                            </Row>
                                          }
                                        />
                                      </Card>
                                    </Col>
                                  </Row>
                                </Popover>
                              ))
                            )}
                          </div>
                        </Col>
                      )}
                    </Row>
                  )}
                </div>
              )}
            </>
          )}
          {
            // (mapData.mapData.loading === false && mapData.mapData.data.length) ||
            matrixData.matrixData.length && SelectedModeVioCount !== 0 ? (
              <span
                class="chartToggler"
                style={{ float: activeMap === "scatter" ? "right" : "right" }}
              >
                <Tooltip
                  placement="bottom"
                  title={
                    <span>
                      {activeMap === "scatter"
                        ? "Show world map view"
                        : "Show matrix view"}
                    </span>
                  }
                >
                  <Button
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      border: "white",
                    }}
                    onClick={toggleMap}
                    type="primary"
                    shape="round"
                    icon={
                      activeMap === "scatter" ? (
                        <Image width={30} src={MapIcon} preview={false} />
                      ) : (
                        // <GlobalOutlined style={{ fontSize: 20 }} />
                        // <BarChartOutlined style={{ fontSize: 20 }} />
                        <Image width={30} src={MatrixIcon} preview={false} />
                      )
                    }
                    size="large"
                  />
                </Tooltip>
              </span>
            ) : (
              <></>
            )
          }
        </div>
      )}
    </React.Fragment>
  );
};

export default Banner;
