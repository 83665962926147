import React, { useEffect, useState } from "react";
import "./barchartstyle.css";
export default function CustomBarChart({
  selectedMode,
  selected_E,
  selected_D,
  selected_I,
  getendoData,
  setSelected_E,
  setSelected_D,
  setSelected_I,
  endoCUT_Ref
}) {
  var _m = selectedMode;
  var _e = selected_E;
  var _d = selected_D;
  var _i = selected_I;

  const WIDTH = 1000;
  const HEIGHT = 600;

  const WIDTH_OFFSET = 20;
  const HEIGHT_OFFSET = 20;

  const BAR_E_HEIGHT_MAX = 300;
  const BAR_D_WIDTH_MAX = 80;
  const DIST_I_WIDTH_MAX = 150;

  const BAR_STEP_HEIGHT_MAX = 450;
  const BAR_STEP_HEIGHT_MID = 400;
  const BAR_STEP_HEIGHT_MIN = 350;

  const MAJOR_STEP_WIDTH = 270;

  const MAX_E = 4;
  const MAX_I = 10;
  const MAX_D = 4;

  const AXIS_THICKNESS = 2;
  const BRACKET_THICKNESS = 2;
  const BRACKET_HEIGHT = 20;

  const BRACKET_E_OFFSET = 20;
  const BRACKET_D_OFFSET = 50;
  const BRACKET_I_OFFSET = 15;

  useEffect(() => {
    SetGraph(selectedMode, selected_E, selected_D, selected_I);
  }, [selectedMode, selected_E, selected_D, selected_I]);
  const SetGraph = (m, e, d, i) => {
    _m = m;
    if (e >= MAX_E) _e = MAX_E;
    else if (e <= 1) _e = 1;
    else _e = e;

    if (d >= MAX_D) _d = MAX_D;
    else if (d <= 1) _d = 1;
    else _d = d;

    if (i >= MAX_I) _i = MAX_I;
    else if (i <= 1) _i = 1;
    else _i = i;
    if (_e == MAX_E) {
      HideElement("bracket_e_nextbtn");
    } else {
      ShowElement("bracket_e_nextbtn");
    }

    // if (_e <= 1) {
    //   HideElement("bracket_e_btn");
    //   HideElement("bracket_e_prevbtn");
    //   HideElement("bracket_e_nextbtn");
    //   HideElement("bracket_e_value");
    //   HideElement("bracket_e_legend");
    // } else {
    //   ShowElement("bracket_e_btn");
    //   ShowElement("bracket_e_prevbtn");
    //   if (_e == MAX_E) ShowElement("bracket_e_nextbtn");
    //   ShowElement("bracket_e_value");
    //   ShowElement("bracket_e_legend");
    // }

    if (_d == MAX_D) HideElement("bracket_d_nextbtn");
    else ShowElement("bracket_d_nextbtn");

    if (_i == MAX_I) HideElement("bracket_i_nextbtn");
    else ShowElement("bracket_i_nextbtn");

    // if (_e <= 1) {
    //   HideElement("bracket_e_btn");
    //   HideElement("bracket_e_prevbtn");
    //   HideElement("bracket_e_nextbtn");
    //   HideElement("bracket_e_value");
    //   HideElement("bracket_e_legend");
    // } else {
    //   ShowElement("bracket_e_btn");
    //   ShowElement("bracket_e_prevbtn");
    //   ShowElement("bracket_e_nextbtn");
    //   ShowElement("bracket_e_value");
    //   ShowElement("bracket_e_legend");
    // }
    if (_e == 1) {
      HideElement("bracket_e_prevbtn");
    } else {
      ShowElement("bracket_e_prevbtn");
    }

    if (_d == 1) HideElement("bracket_d_prevbtn");
    else ShowElement("bracket_d_prevbtn");

    if (_i == 1) HideElement("bracket_i_prevbtn");
    else ShowElement("bracket_i_prevbtn");

    var _CutDurationWidth = 10 + (BAR_D_WIDTH_MAX / 15) * _d;
    var _IntervalWidth = MAJOR_STEP_WIDTH - (10 - _i) * 5;

    SetSize("svgEndoCutGraph", WIDTH, HEIGHT);
    SetAxis(
      "xAxis",
      0,
      HEIGHT - HEIGHT_OFFSET,
      WIDTH - WIDTH_OFFSET - 10,
      HEIGHT - HEIGHT_OFFSET
    );
    SetAxis("yAxis", WIDTH_OFFSET, HEIGHT_OFFSET, WIDTH_OFFSET, HEIGHT);
    SetElementXY("yLabel", WIDTH_OFFSET, HEIGHT_OFFSET - 10);
    SetElementXY(
      "xLabel",
      WIDTH - WIDTH_OFFSET - 10 + 2,
      HEIGHT - HEIGHT_OFFSET
    );

    var _BlueBarHeight = 0;
    if (_e <= 1) {
      SetRect("bluebar", WIDTH_OFFSET + AXIS_THICKNESS, HEIGHT_OFFSET, 0, 0);
    } else if (_e == 2) {
      _BlueBarHeight = BAR_E_HEIGHT_MAX / 2;
      SetRect(
        "bluebar",
        WIDTH_OFFSET + AXIS_THICKNESS,
        HEIGHT - HEIGHT_OFFSET - BAR_E_HEIGHT_MAX / 2,
        _IntervalWidth * 3 + BAR_D_WIDTH_MAX,
        BAR_E_HEIGHT_MAX / 2
      );
    } else if (_e > 2) {
      _BlueBarHeight = BAR_E_HEIGHT_MAX;
      SetRect(
        "bluebar",
        WIDTH_OFFSET + AXIS_THICKNESS,
        HEIGHT - HEIGHT_OFFSET - BAR_E_HEIGHT_MAX,
        _IntervalWidth * 3 + BAR_D_WIDTH_MAX,
        BAR_E_HEIGHT_MAX
      );
    }

    var _BarHeights = [];
    if (_m == "I") {
      _BarHeights.push(BAR_STEP_HEIGHT_MID);
      _BarHeights.push(BAR_STEP_HEIGHT_MID);
      _BarHeights.push(BAR_STEP_HEIGHT_MID);
      _BarHeights.push(BAR_STEP_HEIGHT_MID);
    } else if ((_m = "Q")) {
      _BarHeights.push(BAR_STEP_HEIGHT_MIN);
      _BarHeights.push(BAR_STEP_HEIGHT_MID);
      _BarHeights.push(BAR_STEP_HEIGHT_MAX);
      _BarHeights.push(BAR_STEP_HEIGHT_MAX);
    }

    for (i = 1; i <= 4; i++) {
      var barId1 = "bar" + i + "1";
      var barId2 = "bar" + i + "2";
      var x_pos = WIDTH_OFFSET + AXIS_THICKNESS + (i - 1) * _IntervalWidth;
      var x_wid = BAR_D_WIDTH_MAX - _CutDurationWidth;
      SetRect(
        barId1,
        x_pos,
        HEIGHT - HEIGHT_OFFSET - _BarHeights[i - 1],
        x_wid,
        _BarHeights[i - 1]
      );
      SetRect(
        barId2,
        x_pos + x_wid,
        HEIGHT - HEIGHT_OFFSET - _BarHeights[i - 1],
        _CutDurationWidth,
        _BarHeights[i - 1]
      );
    }
    var bracket_e_x =
      WIDTH_OFFSET +
      AXIS_THICKNESS +
      _IntervalWidth +
      BRACKET_E_OFFSET +
      BAR_D_WIDTH_MAX;
    if (_e <= 1) {
      SetRect(
        "bracket_e_top",
        bracket_e_x - BRACKET_HEIGHT / 2,
        HEIGHT - HEIGHT_OFFSET - _BlueBarHeight,
        0,
        0
      );
      SetRect(
        "bracket_e_bottom",
        bracket_e_x - BRACKET_HEIGHT / 2,
        HEIGHT - HEIGHT_OFFSET - 2,
        0,
        0
      );
      SetRect(
        "bracket_e_line",
        bracket_e_x,
        HEIGHT - HEIGHT_OFFSET - _BlueBarHeight,
        0,
        0
      );
      SetRect(
        "bracket_e_btn",
        bracket_e_x + 20,
        HEIGHT - HEIGHT_OFFSET - 55,
        80,
        20
      );
      SetElementXY(
        "bracket_e_prevbtn",
        bracket_e_x + 24,
        HEIGHT - HEIGHT_OFFSET - 40
      );
      SetElementXY(
        "bracket_e_value",
        bracket_e_x + 54,
        HEIGHT - HEIGHT_OFFSET - 40
      );
      SetElementXY(
        "bracket_e_nextbtn",
        bracket_e_x + 84,
        HEIGHT - HEIGHT_OFFSET - 40
      );
      SetElementXY(
        "bracket_e_legend",
        bracket_e_x + 24,
        HEIGHT - HEIGHT_OFFSET - 15
      );
      SetValue("bracket_e_value", _e);
    } else {
      SetRect(
        "bracket_e_top",
        bracket_e_x - BRACKET_HEIGHT / 2,
        HEIGHT - HEIGHT_OFFSET - _BlueBarHeight,
        BRACKET_HEIGHT,
        BRACKET_THICKNESS
      );
      SetRect(
        "bracket_e_bottom",
        bracket_e_x - BRACKET_HEIGHT / 2,
        HEIGHT - HEIGHT_OFFSET - 2,
        BRACKET_HEIGHT,
        BRACKET_THICKNESS
      );
      SetRect(
        "bracket_e_line",
        bracket_e_x,
        HEIGHT - HEIGHT_OFFSET - _BlueBarHeight,
        BRACKET_THICKNESS,
        _BlueBarHeight
      );
      SetRect(
        "bracket_e_btn",
        bracket_e_x + 20,
        HEIGHT - HEIGHT_OFFSET - _BlueBarHeight + 20,
        80,
        20
      );
      SetElementXY(
        "bracket_e_prevbtn",
        bracket_e_x + 24,
        HEIGHT - HEIGHT_OFFSET - _BlueBarHeight + 35
      );
      SetElementXY(
        "bracket_e_value",
        bracket_e_x + 54,
        HEIGHT - HEIGHT_OFFSET - _BlueBarHeight + 35
      );
      SetElementXY(
        "bracket_e_nextbtn",
        bracket_e_x + 84,
        HEIGHT - HEIGHT_OFFSET - _BlueBarHeight + 35
      );
      SetElementXY(
        "bracket_e_legend",
        bracket_e_x + 24,
        HEIGHT - HEIGHT_OFFSET - _BlueBarHeight + 55
      );
      SetValue("bracket_e_value", _e);
    }

    var bracket_d_x =
      WIDTH_OFFSET +
      AXIS_THICKNESS +
      _IntervalWidth * 2 +
      BAR_D_WIDTH_MAX -
      _CutDurationWidth;
    var bracket_d_y =
      HEIGHT - HEIGHT_OFFSET - _BlueBarHeight - BRACKET_D_OFFSET;
    SetRect(
      "bracket_d_left",
      bracket_d_x,
      bracket_d_y - BRACKET_HEIGHT / 2,
      BRACKET_THICKNESS,
      BRACKET_HEIGHT
    );
    SetRect(
      "bracket_d_right",
      bracket_d_x + _CutDurationWidth - BRACKET_THICKNESS,
      bracket_d_y - BRACKET_HEIGHT / 2,
      BRACKET_THICKNESS,
      BRACKET_HEIGHT
    );
    SetRect(
      "bracket_d_line",
      bracket_d_x,
      bracket_d_y,
      _CutDurationWidth,
      BRACKET_THICKNESS
    );
    SetRect(
      "bracket_d_btn",
      bracket_d_x + _CutDurationWidth + 20,
      bracket_d_y - 20,
      80,
      20
    );
    SetElementXY(
      "bracket_d_prevbtn",
      bracket_d_x + _CutDurationWidth + 24,
      bracket_d_y - 5
    );
    SetElementXY(
      "bracket_d_value",
      bracket_d_x + _CutDurationWidth + 54,
      bracket_d_y - 5
    );
    SetElementXY(
      "bracket_d_nextbtn",
      bracket_d_x + _CutDurationWidth + 84,
      bracket_d_y - 5
    );
    SetElementXY(
      "bracket_d_legend",
      bracket_d_x + _CutDurationWidth + 24,
      bracket_d_y + 15
    );
    SetValue("bracket_d_value", _d);

    var bracket_i_x = WIDTH_OFFSET + AXIS_THICKNESS + _IntervalWidth * 2;
    var bracket_i_y =
      HEIGHT - HEIGHT_OFFSET - _BarHeights[3] - BRACKET_I_OFFSET;
    SetRect(
      "bracket_i_left",
      bracket_i_x,
      bracket_i_y - BRACKET_HEIGHT / 2,
      BRACKET_THICKNESS,
      BRACKET_HEIGHT
    );
    SetRect(
      "bracket_i_right",
      bracket_i_x + _IntervalWidth,
      bracket_i_y - BRACKET_HEIGHT / 2,
      BRACKET_THICKNESS,
      BRACKET_HEIGHT
    );
    SetRect(
      "bracket_i_line",
      bracket_i_x,
      bracket_i_y,
      _IntervalWidth,
      BRACKET_THICKNESS
    );
    SetRect("bracket_i_btn", bracket_i_x, bracket_i_y - 45, 80, 20);
    SetElementXY("bracket_i_prevbtn", bracket_i_x + 4, bracket_i_y - 30);
    SetElementXY("bracket_i_value", bracket_i_x + 34, bracket_i_y - 30);
    SetElementXY("bracket_i_nextbtn", bracket_i_x + 64, bracket_i_y - 30);
    SetElementXY("bracket_i_legend", bracket_i_x + 4, bracket_i_y - 10);
    SetValue("bracket_i_value", _i);
  };
  const SetSize = (element, width, height) => {
    document.getElementById(element).setAttribute("width", width);
    document.getElementById(element).setAttribute("height", height);
  };

  const SetRect = (element, x, y, w, h) => {
    document.getElementById(element).setAttribute("x", x);
    document.getElementById(element).setAttribute("y", y);
    SetSize(element, w, h);
  };

  const SetAxis = (axis, x1, y1, x2, y2) => {
    document.getElementById(axis).setAttribute("x1", x1);
    document.getElementById(axis).setAttribute("x2", x2);
    document.getElementById(axis).setAttribute("y1", y1);
    document.getElementById(axis).setAttribute("y2", y2);
  };

  const SetElementXY = (element, x, y) => {
    document.getElementById(element).setAttribute("x", x);
    document.getElementById(element).setAttribute("y", y);
  };

  const SetValue = (element, value) => {
    console.log(element + value);
    document.getElementById(element).textContent = value.toString();
  };

  const ShowElement = (element) => {
    document.getElementById(element).style.visibility = "";
  };

  const HideElement = (element) => {
    document.getElementById(element).style.visibility = "hidden";
  };

  const EffectPrev = () => {
    SetGraph(_m, _e - 1, _d, _i);
    // setSelected_E(_e);
    // setSelected_D(_d);
    // setSelected_I(_i);
    getendoData(_m, _e, _d, _i);
  };

  const EffectNext = () => {
    // getendoData(_m, _e + 1, _d, _i);
    SetGraph(_m, _e + 1, _d, _i);
    // setSelected_E(_e);
    // setSelected_D(_d);
    // setSelected_I(_i);
    getendoData(_m, _e, _d, _i);
  };

  const DurationPrev = () => {
    // getendoData(_m, _e, _d - 1, _i);
    SetGraph(_m, _e, _d - 1, _i);
    // setSelected_E(_e);
    // setSelected_D(_d);
    // setSelected_I(_i);
    getendoData(_m, _e, _d, _i);
  };

  const DurationNext = () => {
    // getendoData(_m, _e, _d + 1, _i);
    SetGraph(_m, _e, _d + 1, _i);
    // setSelected_E(_e);
    // setSelected_D(_d);
    // setSelected_I(_i);
    getendoData(_m, _e, _d, _i);
  };

  const IntervalPrev = () => {
    SetGraph(_m, _e, _d, _i - 1);
    console.log("sdasfasdas", endoCUT_Ref)
    // setSelected_E(_e);
    // setSelected_D(_d);
    // setSelected_I(_i);
    // console.log("the data is", _m, _e, _d, _i);
    getendoData(_m, _e, _d, _i);
  };

  const IntervalNext = () => {
    SetGraph(_m, _e, _d, _i + 1);
    // setSelected_E(_e);
    // setSelected_D(_d);
    // setSelected_I(_i);
    getendoData(_m, _e, _d, _i);
  };

  return (
    <div
      className="chart"
      style={{
        backgroundColor: "#333023",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <svg
        id="svgEndoCutGraph"
        height={400}
        width={600}
        version="1.2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className="graph"
        aria-labelledby="title"
        role="img"
      >
        <g className="grid x-grid" id="xGrid">
          <line
            id="xAxis"
            className="axis-line"
            x1="50"
            x2="50"
            y1="15"
            y2="485"
          ></line>
        </g>
        <g className="grid y-grid" id="yGrid">
          <line
            id="yAxis"
            className="axis-line"
            x1="50"
            x2="700"
            y1="485"
            y2="485"
          ></line>
        </g>
        <g className="labels x-labels">
          <text id="xLabel" x="715" y="490" className="label-axis">
            Time
          </text>
        </g>
        <g className="labels y-labels">
          <text id="yLabel" x="40" y="10" className="label-axis">
            Voltage
          </text>
        </g>
        <g>
          <rect
            id="bluebar"
            className="bluebar"
            x="52"
            width="648"
            y="350"
            height="132"
          ></rect>
        </g>
        <g>
          <rect id="bar11" className="yellowbar1"></rect>
          <rect id="bar12" className="yellowbar2"></rect>
        </g>
        <g>
          <rect id="bar21" className="yellowbar1"></rect>
          <rect id="bar22" className="yellowbar2"></rect>
        </g>
        <g>
          <rect id="bar31" className="yellowbar1"></rect>
          <rect id="bar32" className="yellowbar2"></rect>
        </g>
        <g>
          <rect id="bar41" className="yellowbar1"></rect>
          <rect id="bar42" className="yellowbar2"></rect>
        </g>
        <g>
          <rect id="bracket_e_top" className="linebar"></rect>
          <rect id="bracket_e_line" className="linebar"></rect>
          <rect id="bracket_e_bottom" className="linebar"></rect>
          <rect
            id="bracket_e_btn"
            rx="8"
            style={{ fill: "#008ed6", color: "white", fontWeight: "bold" }}
          />
          {/* <rect id="bracket_e_btn" style={{ opacity: "0.7", fill: "gold" }} /> */}
          {/* <text
            id="bracket_e_prevbtn"
            x="0"
            y="15"
            className="fas fa-arrow-left"
            style={{ cursor: "hand" }}
            onClick={() => EffectPrev()}
          >
            p
          </text> */}
          <text
            id="bracket_e_prevbtn"
            x="0"
            y="15"
            className="fas fa-arrow-left"
            style={{ cursor: "hand", fill: "white" }}
            onClick={() => EffectPrev()}
          >
            &#xf060;
          </text>
          <text id="bracket_e_value" className="label-title"></text>
          <text
            id="bracket_e_nextbtn"
            x="0"
            y="15"
            className="fas fa-arrow-right"
            style={{ cursor: "hand", fill: "white" }}
            onClick={() => EffectNext()}
          >
            &#xf061;
          </text>
          {/* <text
            id="bracket_e_nextbtn"
            className="label-title"
            style={{ cursor: "hand" }}
            onClick={() => EffectNext()}
          >
            n
          </text> */}
          <text id="bracket_e_legend" className="label-title">
            effect (E)
          </text>
        </g>
        <g>
          <rect id="bracket_d_left" className="linebar"></rect>
          <rect id="bracket_d_line" className="linebar"></rect>
          <rect id="bracket_d_right" className="linebar"></rect>
          <rect id="bracket_d_btn" rx="8" style={{ fill: "#FFD700" }} />
          <text
            id="bracket_d_prevbtn"
            x="0"
            y="15"
            className="fas fa-arrow-left"
            style={{ cursor: "hand", fill: "white" }}
            onClick={() => DurationPrev()}
          >
            &#xf060;
          </text>
          {/* <text
            id="bracket_d_prevbtn"
            className="label-title"
            style={{ cursor: "hand", color: "white" }}
            onClick={() => DurationPrev()}
          >
            p
          </text> */}
          <text id="bracket_d_value" className="label-title"></text>
          <text
            id="bracket_d_nextbtn"
            x="0"
            y="15"
            className="fas fa-arrow-right"
            style={{ cursor: "hand", fill: "white" }}
            onClick={() => DurationNext()}
          >
            &#xf061;
          </text>

          {/* <text
            id="bracket_d_nextbtn"
            className="label-title"
            style={{ cursor: "hand" }}
            onClick={() => DurationNext()}
          >
            n
          </text> */}
          <text id="bracket_d_legend" className="label-title">
            cutting duration (D)
          </text>
        </g>
        <g>
          <rect id="bracket_i_left" className="linebar"></rect>
          <rect id="bracket_i_line" className="linebar"></rect>
          <rect id="bracket_i_right" className="linebar"></rect>
          <rect id="bracket_i_btn" rx="8" style={{ fill: "#FFD700" }} />
          <text
            id="bracket_i_prevbtn"
            x="0"
            y="15"
            className="fas fa-arrow-left"
            style={{ cursor: "hand", fill: "white" }}
            onClick={() => IntervalPrev()}
          >
            &#xf060;
          </text>
          {/* <text
            id="bracket_i_prevbtn"
            className="label-title"
            style={{ cursor: "hand" }}
            onClick={() => IntervalPrev()}
          >
            p
          </text>  */}
          <text id="bracket_i_value" className="label-title"></text>
          <text
            id="bracket_i_nextbtn"
            x="0"
            y="15"
            className="fas fa-arrow-right"
            style={{ cursor: "hand", fill: "white" }}
            onClick={() => IntervalNext()}
          >
            &#xf061;
          </text>

          {/* <text
            id="bracket_i_nextbtn"
            className="label-title"
            style={{ cursor: "hand" }}
            onClick={() => IntervalNext()}
          >
            n
          </text> */}
          <text id="bracket_i_legend" className="label-title">
            interval (I)
          </text>
        </g>
      </svg>
    </div>
  );
}
