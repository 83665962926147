const initialState = {
  modesData: {
    data: [],
    loading: false,
  },
};

export const ModesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MODES_SUCCESS":
      return {
        ...state,
        modesData: {
          data: action.payload.data,
          loading: action.payload.loading,
        },
      };
    case "GET_MODES":
      return {
        ...state,
        modesData: {
          ...state.modesData,
          loading: action.payload.loading,
        },
      };
    case "GET_MODES_ERROR":
      return {
        ...state,
        modesData: {
          ...state.modesData,
          loading: action.payload.loading,
        },
      };
    default:
      return state;
  }
};
