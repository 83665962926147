import axios from "axios";
import { BASEURL } from "../config";
export const getMap_data = (discipline, intro, mode) => {
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  let formData = {
    Intervention: intro,
    Discipline: discipline,
    Mode: mode,
  };
  return (dispatch) => {
    dispatch({
      type: "GET_MAP",
      payload: {
        loading: true,
      },
    });
    axios
      .post(`${BASEURL}/map`, formData, headers)
      .then((res) => {
        dispatch({
          type: "GET_MAP_SUCCESS",
          payload: {
            data: res?.data,
            loading: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MAP_ERROR",
          payload: {
            loading: false,
          },
        });
        console.log(error);
      });
  };
};
export const getMapvideos_data = (inter, discipline, mode, country, city) => {
  let formData = {
    Intervention: inter,
    Discipline: discipline,
    Mode: mode,
    Country: country,
    City: city,
  };
  return (dispatch) => {
    dispatch({
      type: "GET_MAPVIDEOS",
      payload: {
        loading: true,
      },
    });
    axios
      .post(`${BASEURL}/map/videos`, formData)
      .then((res) => {
        dispatch({
          type: "GET_MAPVIDEOS_SUCCESS",
          payload: {
            data: res?.data,
            loading: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MAPVIDEOS_ERROR",
          payload: {
            loading: false,
          },
        });
        console.log(error);
      });
  };
};
export const clearMap_data = () => {
  return (dispatch) => {
    dispatch({
      type: "GET_MAP_SUCCESS",
      payload: {
        data: [],
        loading: false,
      },
    });
  };
};
