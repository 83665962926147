import React from "react";
import "antd/dist/antd.css";
import { Layout } from "antd";
import Headermenu from "./layout/header";
import Footeritem from "./layout/footer";
import Banner from "./components/dashboard/banner";
import SubmitVideo from "./components/dashboard/submitVideo";
import NewsContent from "./components/dashboard/news";
import OtherProduct from "./components/dashboard/OtherProduct";
import ClinicalTeam from "./components/dashboard/clinicalTeam";
function App() {
  return (
    <Layout>
      <Headermenu />
      <Banner />
      <SubmitVideo />
      {/* <NewsContent /> */}
      {/* <OtherProduct /> */}
      <ClinicalTeam />
      <Footeritem />
    </Layout>
  );
}

export default App;
